import React from "react";
import { History } from "react-router-dom/node_modules/history";
import { IDropdownOption, DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import { ICommandBarItemProps, ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IFilter, IFilterItem } from "./interfaces";
import classNames from "./commonStyles.scss";
import { Account } from "msal";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import { IconButton } from "@fluentui/react/lib/Button";
import { ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { isUserSoxAuditor } from "../../app/App.helper";
import { IUserInfo } from "../../app/interfaces";

export const impactScoreColors = [
  "#081",
  "#27880d",
  "#5a810c",
  "#6d7b0d",
  "#90730e",
  "#9f650e",
  "#b75b0f",
  "#c64c0f",
  "#d63c0f",
  "#E01",
  "#C01",
];

export const getImpactScoreColor = (score: number): string =>
  score !== undefined && score < impactScoreColors.length ? impactScoreColors[score] : "grey";

let uniqueId = 0;

export const renderImpactScore = (score: number) => {
  const style = { backgroundColor: getImpactScoreColor(score) };
  const tooltipId = `tooltip${uniqueId}`;
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
  const ariaLabel =
    score === undefined ? "?" : `${score}. Value is in range of 0 to 10. 0 = no impact. 10 = high impact`;

  uniqueId += 1;

  return (
    <TooltipHost
      content={`Value of 0 to 10\n 0 = no impact\n10 = high impact`}
      // This id is used on the tooltip itself, not the host
      // (so an element with this id only exists when the tooltip is shown)
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
      tabIndex={0}
    >
      <div
        data-is-focusable="true"
        className={`${classNames.impactScore} impactScore`}
        style={style}
        aria-label={ariaLabel}
      >
        {score === undefined ? "?" : score}
      </div>
    </TooltipHost>
  );
};

export const getImpactScore = (controlResult) =>
  controlResult.resultStatus === undefined
    ? undefined
    : controlResult.impactScoreOverride !== undefined && controlResult.impactScoreOverride !== null
    ? controlResult.impactScoreOverride
    : controlResult.impactScore !== undefined && controlResult.impactScore !== null
    ? controlResult.impactScore
    : controlResult.resultStatus === "pass"
    ? 0
    : 10;

export const isFalseAlarm = (controlResult): boolean =>
  controlResult.statusState === "acknowledged" && controlResult.statusReason === "falseAlarm";

export const renderImpactScoreByControl = (controlResult) => {
  var score = getImpactScore(controlResult);
  return renderImpactScore(score);
};

export const getFilterOptions = (filters: IFilter[]): IDropdownOption[] => {
  const filterOptions: IDropdownOption[] = [];

  filters &&
    filters.forEach((filter, fIndex) => {
      if (filter.name) {
        filterOptions.push({
          key: filter.name.trim(),
          text: filter.name,
          itemType: DropdownMenuItemType.Header,
        });
      }

      filter.items &&
        filter.items.forEach((item) => {
          filterOptions.push({ key: item.value?.trim(), text: item.name, ariaLabel: item.name });
        });

      if (fIndex < filters.length - 1) {
        filterOptions.push({ key: "divider" + fIndex, text: "-", itemType: DropdownMenuItemType.Divider });
      }
    });

  return filterOptions;
};

export const getFilterOptionsByUrl = (filters: IFilter[], userInfo: IUserInfo): IDropdownOption[] => {
  const filterOptions: IDropdownOption[] = [];

  const setFilterOption = (filterItems: IFilterItem[]) => {
    filterItems &&
      filterItems
        .filter((item) => !isUserSoxAuditor(userInfo) || !item.hideForSox)
        .forEach((item) => {
          filterOptions.push({ key: item.value.replace(" ", ""), text: item.name, ariaLabel: item.name });
        });
  };

  filters &&
    filters.forEach((filter) => {
      if (filter.name && filter.linkUrl === window.location.hash.substring(1)) {
        setFilterOption(filter.items);
      } else {
        // Check if control id is defined and it's part of the current filter group.
        var url = window.location.hash,
          controlId = url.substring(url.lastIndexOf("/") + 1),
          controlView = filter?.items.find((i) => i.value === controlId);

        if (controlView) {
          setFilterOption(filter.items);
        }
      }
    });

  filterOptions.sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0));

  return filterOptions;
};

export const getPagingCommandBarItems = (
  history: History,
  itemName: string,
  listAction: Function,
  listUrl: string,
  items: any[] = [],
  selectedIndex: number,
  itemAction: Function,
  itemUrl: string = listUrl
): ICommandBarItemProps[] => {
  if (!items || !items.length) return [];

  let result: ICommandBarItemProps[] = [];

  if (listAction) {
    result.push({
      key: "list",
      name: `Back to ${itemName} List`,
      ariaLabel: `Back to ${itemName} List`,
      iconProps: {
        iconName: "ReturnToSession",
      },
      iconOnly: true,
      onClick: (ev: any) => {
        ev.stopPropagation();
        listAction();
        listUrl && history.push(listUrl);
      },
    });
  }

  if (items.length > 1 && selectedIndex >= 0) {
    result = result.concat([
      {
        key: "prev",
        name: `Previous ${itemName}`,
        ariaLabel: `Previous ${itemName}`,
        iconProps: {
          iconName: "CaretLeftSolid8",
        },
        iconOnly: true,
        disabled: selectedIndex <= 0,
        onClick: (ev: any) => {
          ev.stopPropagation();
          if (selectedIndex > 0) {
            const prevItem = items[selectedIndex - 1];
            itemAction(prevItem);
            itemUrl && history.push(`${itemUrl}/${prevItem.incidentId || prevItem.id}`); //TODO: Refactor Incident to use id, not incidentId.
          }
        },
      },
      {
        id: "pageNumbers",
        key: "pageNumbers",
        name: `${selectedIndex + 1} of ${items.length}`,
        disabled: true,
      },
      {
        key: "next",
        name: `Next ${itemName}`,
        ariaLabel: `Next ${itemName}`,
        iconProps: {
          iconName: "CaretRightSolid8",
        },
        iconOnly: true,
        disabled: selectedIndex < 0 || selectedIndex >= items.length - 1,
        onClick: (ev: any) => {
          ev.stopPropagation();
          if (selectedIndex <= items.length - 2) {
            const nextItem = items[selectedIndex + 1];
            itemAction(nextItem);
            itemUrl && history.push(`${itemUrl}/${nextItem.incidentId || nextItem.id}`); //TODO: Refactor Incident to use id, not incidentId.
          }
        },
      },
    ]);
  }

  return result;
};

export enum CommandBarActionKey {
  refresh = "refresh",
  setDefaultPage = "setDefaultPage",
  unsetDefaultPage = "unsetDefaultPage",
}

export interface ICommandBarActions {
  [key: string]: Function;
}

export const getBasicPageCommandBarItems = (
  isSmallScreen: boolean,
  commandBarActions: ICommandBarActions,
  hideDefaultPageAction: boolean = false
) => {
  const onClick = (ev: any, key: CommandBarActionKey) => {
    ev.stopPropagation();
    const action = commandBarActions && commandBarActions[key];
    action && action();
  };

  const isDefaultPage =
    localStorage.defaultPage === window.location.pathname + window.location.hash + window.location.search;

  let commandBarItems: any = [
    {
      key: CommandBarActionKey.refresh,
      name: isSmallScreen ? "Refresh Data" : "Refresh",
      title: isSmallScreen ? "" : "Refresh Data",
      iconProps: {
        iconName: "Refresh",
      },
      iconOnly: isSmallScreen,
      disabled: !commandBarActions || !commandBarActions.refresh,
      onClick: (ev: any) => onClick(ev, CommandBarActionKey.refresh),
    },
  ];

  if (!hideDefaultPageAction) {
    commandBarItems.unshift(
      isDefaultPage
        ? {
            key: CommandBarActionKey.unsetDefaultPage,
            name: isSmallScreen ? "Unset Default" : "Unset Default Page",
            title: "Unset this page as the default landing page",
            iconProps: {
              iconName: "HomeSolid",
            },
            iconOnly: isSmallScreen,
            onClick: (ev: any) => {
              onClick(ev, CommandBarActionKey.unsetDefaultPage);
              localStorage.removeItem("defaultPage");
              window.location.reload();
            },
          }
        : {
            key: CommandBarActionKey.setDefaultPage,
            name: isSmallScreen ? "Set Default" : "Set Default Page",
            title: "Set this page as the default landing page",
            iconProps: {
              iconName: "Home",
            },
            iconOnly: isSmallScreen,
            onClick: (ev: any) => {
              onClick(ev, CommandBarActionKey.setDefaultPage);
              localStorage.defaultPage = window.location.pathname + window.location.hash + window.location.search;
              window.location.reload();
            },
          }
    );
  }

  return commandBarItems;
};

export const getRefreshButton = (onClick: () => void, isSmallScreen?: boolean, isDisabled?: boolean) => {
  return {
    key: "refresh",
    name: "Refresh",
    title: "Refresh",
    iconProps: {
      iconName: "Refresh",
    },
    iconOnly: isSmallScreen,
    disabled: isDisabled,
    onClick,
  };
};

export const getPrintButton = (
  onClick: () => void,
  isSmallScreen?: boolean,
  isDisabled?: boolean
): ICommandBarItemProps => {
  return {
    key: "print",
    name: "Print",
    title: "Print",
    iconProps: {
      iconName: "print",
    },
    iconOnly: isSmallScreen,
    disabled: isDisabled,
    onClick,
  };
};

export const getUserId = (user: Account): string => {
  let upn = (user && user.userName) || "",
    domainIndex = upn.indexOf("@");

  return domainIndex >= 0 ? upn.substr(0, domainIndex) : upn;
};

export const getExcelDownloadCommandBarProps = (
  downloadDataToExcel: Function,
  items: any[],
  worksheetName: string
): ICommandBarProps => {
  if (!downloadDataToExcel || !items || !items.length) return null;

  return {
    items: [],
    overflowItems: [getExcelDownloadCommandBarItem(downloadDataToExcel, items, worksheetName)],
  };
};

export const getExcelDownloadCommandBarItem = (downloadDataToExcel: Function, items: any[], worksheetName: string) => ({
  key: "excelExport",
  name: "Export To Excel",
  title: "Export data to Excel",
  ariaLabel: "Export To Excel",
  iconProps: { iconName: "ExcelDocument" },
  onClick: () => downloadDataToExcel(items, worksheetName),
});

export const addDetailsPageLinkColumn = (origColumns: IColumn[], onColumnClick: Function): IColumn[] => {
  let columns = origColumns.slice();

  columns.unshift({
    key: "detailsPageLink",
    name: "Details",
    fieldName: "detailsPageLink",
    minWidth: 40,
    maxWidth: 40,
    isResizable: true,
    className: classNames.detailsLinkColumn,
    onRender: (item: any) => (
      <IconButton
        iconProps={{ iconName: "TextDocument" }}
        title="Go to Details Page"
        aria-label="Go to Details Page"
        className={classNames.itemAction}
        onClick={() => onColumnClick(item)}
      />
    ),
  });

  return columns;
};

export const shouldRefreshCopilotCloseStatus = () => window.location.hash.indexOf("refreshCopilotCloseStatus") >= 0;
