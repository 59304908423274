import React from "react";
import { SelectionMode, CheckboxVisibility } from "@fluentui/react/lib/DetailsList";
import { IControlReport } from "./interfaces";
import classNames from "./ControlItemList.module.scss";
import { ICommandBarProps, Spinner } from "@fluentui/react";
import { getExcelDownloadCommandBarProps, addDetailsPageLinkColumn } from "../common/helper";
import TileDetailsList from "../../shared/components/Tile/TileDetailsList";

export interface IControlItemListProps {
  controlReport: IControlReport;
  loading: boolean;
  error: string;
  noItemText: string;
  title: string;
  className?: string;
  allowSelection?: boolean;
  downloadDataToExcel?: (data: any[], worksheetName: string) => void;
  onRowSelection?: (item: any) => void;
}

export const ControlItemList = (props: IControlItemListProps) => {
  const { title, loading, error, noItemText, className, allowSelection, controlReport, onRowSelection } = props;

  if (loading) {
    return <Spinner className={classNames.spinner} />;
  }

  if (error) {
    return <div className={classNames.error}>{error["message"] || error}</div>;
  }

  if (!controlReport || !controlReport.items || !controlReport.columns) {
    return null;
  }

  const { items = [], columns, totalCount } = controlReport;
  const finalColumns = allowSelection ? addDetailsPageLinkColumn(columns, onRowSelection) : columns;

  const getCommandBarProps = (): ICommandBarProps => {
    const { controlReport, downloadDataToExcel } = props;
    const { items } = controlReport || {};

    return getExcelDownloadCommandBarProps(downloadDataToExcel, items, "Control Items");
  };

  let itemUnit = items.length > 1 ? "s" : "",
    itemCountText =
      !totalCount || items.length === totalCount
        ? `${items.length} item${itemUnit}`
        : `${items.length} of ${totalCount} item${itemUnit} displayed`;

  return (
    <TileDetailsList
      title={`${title} <span style='font-size:9pt'>(${itemCountText})</span>`}
      className={className}
      commandBarProps={getCommandBarProps()}
      showSearchBox
      classNameForDetailsList={classNames.list}
      items={items}
      columns={finalColumns}
      compact={false}
      selectionMode={SelectionMode.single}
      checkboxVisibility={CheckboxVisibility.hidden}
      noItemText={noItemText}
    />
  );
};

export default ControlItemList;
