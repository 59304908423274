import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "@fluentui/react";
import classNames from "./TestDataSourceModal.module.scss";
import { testDataSource } from "../../../api/radarApi";
import ConfirmModal from "../../common/ConfirmModal";
import { DataForm, FieldType, IField } from "../../../shared/components/DataForm";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";

export interface ITestDataSourceModalProps {
  hidden?: boolean;
  useQueryParameters?: boolean;
  dataSource?: object;
  onDismiss?: () => void;
}

export const TestDataSourceModal = (props: ITestDataSourceModalProps) => {
  const { hidden = true, dataSource, useQueryParameters, onDismiss } = props;
  const [testDataSourceResult, setTestDataSourceResult] = useState<any>();
  const [loadingTestData, setLoadingTestData] = useState<boolean>(false);
  const [queryParameters, setQueryParameters] = useState<object>({});
  const [query, setQuery] = useState<string>();

  useEffect(() => {
    !hidden && setTestDataSourceResult(undefined);
  }, [hidden]);

  useEffect(() => {
    if (dataSource && dataSource["query"]) {
      setQuery(dataSource["query"]);
    }
  }, [dataSource]);

  const onTestDataSourceStart = useCallback(() => {
    if (dataSource) {
      setLoadingTestData(true);
      setTestDataSourceResult(undefined);

      let newDataSource = {
        ...dataSource,
        query,
      };

      testDataSource(newDataSource)
        .then((result) => setTestDataSourceResult(result || []))
        .catch((error) => setTestDataSourceResult(error))
        .finally(() => setLoadingTestData(false));
    }
  }, [dataSource, query]);

  const onQueryParametersChange = useCallback(
    (fieldName: string, newValue: any[]) => {
      let newParameters = {};
      newParameters[fieldName] = newValue;

      if (dataSource && newValue?.length) {
        let newQuery = dataSource["query"].slice();
        newValue.forEach((parameter) => {
          newQuery = newQuery.replaceAll("{" + parameter.name + "}", parameter.value);
        });
        setQuery(newQuery);
      }

      setQueryParameters(newParameters);
    },
    [dataSource]
  );

  const onCancel = useCallback(() => {
    setQueryParameters({});
    onDismiss && onDismiss();
  }, [onDismiss]);

  return (
    <>
      {!hidden && (
        <ConfirmModal
          className={classNames.root}
          onCommit={onTestDataSourceStart}
          onCancel={onCancel}
          title="Test Data Source"
          commitButtonText="Start"
          disableCommit={!dataSource || loadingTestData}
          modalRootClassName={classNames.modalRoot}
        >
          {!dataSource ? (
            <div className={classNames.error}>No data source is defined.</div>
          ) : (
            <>
              {useQueryParameters && (
                <DataForm
                  className={classNames.dataForm}
                  fields={queryParamFields}
                  context={queryParameters}
                  onFieldValueChange={onQueryParametersChange}
                />
              )}
              <h1>Query:</h1>
              <div className={classNames.query}>{query}</div>
              {loadingTestData && (
                <div className={classNames.spinnerContainer}>
                  <Spinner className={classNames.spinner} label="Loading test data..." />
                </div>
              )}
              {!loadingTestData && testDataSourceResult && !testDataSourceResult["error"] && (
                <>
                  <h1>Query Result:</h1>
                  <div className={classNames.testResult}>
                    {testDataSourceResult["result"]?.length
                      ? JSON.stringify(testDataSourceResult["result"], null, 2)
                      : "No query result is returned."}
                  </div>
                </>
              )}
              {!loadingTestData && testDataSourceResult && testDataSourceResult["error"] && (
                <>
                  <h1>Error:</h1>
                  <div className={classNames.testResult}>{JSON.stringify(testDataSourceResult["error"], null, 2)}</div>
                </>
              )}
            </>
          )}
        </ConfirmModal>
      )}
    </>
  );
};

export default TestDataSourceModal;

const queryParamFields: IField[] = [
  {
    fieldName: "queryParameters",
    fieldType: FieldType.items,
    label: "Query Parameters",
    itemTypeName: "Parameter",
    itemDisplayView: ItemDisplayView.list,
    fields: [
      {
        fieldName: "name",
        label: "Name",
      },
      {
        fieldName: "value",
        label: "Value",
      },
    ],
  },
];
