import React, { CSSProperties } from "react";
import { IField, FieldType, IDataContext } from "./interfaces";
import { getCollapsiblePaneStyle } from "./DataForm.helper";
import { Icon } from "@fluentui/react/lib/Icon";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import DataFormContent, { resolveFields } from "./DataFormContent";
import classNames from "./DataForm.module.scss";
import { SearchBox } from "@fluentui/react";

export enum ItemDisplayView {
  list = "list",
  tab = "tab",
}

export interface IItemContainerProps {
  label: string;
  fieldName: string;
  field: IField;
  fields: IField[] | ((dataContext: IDataContext) => IField[]);
  items: any[];
  itemTypeName?: string;
  compact?: boolean;
  collapsed?: boolean;
  itemDisplayView?: ItemDisplayView;
  parentDataContext?: IDataContext;
  style?: React.CSSProperties;
  hints?: string;
  onFieldValueChange: (fieldName: string, newValue: any) => void;
  onItemSelected?: (fieldName: string, value: any) => void;
}

export interface IItemContainerState {
  collapsed: boolean;
  itemDisplayView: ItemDisplayView;
  selectedKey: string;
  searchText?: string;
}

export class ItemContainer extends React.Component<IItemContainerProps, IItemContainerState> {
  itemsElement: HTMLElement;

  state = {
    collapsed: this.props.collapsed !== false,
    itemDisplayView: this.props.itemDisplayView || ItemDisplayView.tab,
    selectedKey: null,
    searchText: undefined,
  };

  componentDidMount() {
    if (!this.state.selectedKey) {
      // Initial to select the first item.
      const selectedKey = getItemKey("", this.props.itemTypeName, 0);
      this.setState({ selectedKey });
    }
  }

  componentDidUpdate(prevProps) {
    const { items: prevItems } = prevProps;
    const { items: currItems, itemTypeName } = this.props;
    const { searchText } = this.state;

    if (currItems && prevItems && currItems.length > prevItems.length) {
      if (this.itemsElement) {
        let lastItemElement = this.itemsElement.lastElementChild;
        lastItemElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      if (this.state.itemDisplayView === ItemDisplayView.tab) {
        this.setState({ selectedKey: getItemKey(searchText, itemTypeName, 0) });
      }
    }
  }

  render() {
    const { label, fields, items, itemTypeName = "Item", compact, style, hints, field, parentDataContext } = this.props;
    const { collapsed, itemDisplayView, searchText } = this.state;

    const itemsStyle = getCollapsiblePaneStyle(collapsed, "block"),
      isTabView = itemDisplayView === ItemDisplayView.tab;

    const rootClassNames = [classNames.containerRoot];
    compact && rootClassNames.push(classNames.compact);

    let itemsDisplayed = items;

    // Filter items by search text.
    if (isTabView && searchText?.length && items?.length > 0) {
      itemsDisplayed = items.filter((item, index) => {
        let customTab = field.customTabHeader && field.customTabHeader(parentDataContext, itemTypeName, index);
        return (customTab + JSON.stringify(item)).toLowerCase().indexOf(searchText) >= 0;
      });
    }

    const itemsUi = collapsed ? null : !itemsDisplayed?.length ? (
      <div className={classNames.noItemText}>No item is found.</div>
    ) : (
      <>
        {isTabView
          ? this.renderItemTabs(items, itemsDisplayed, itemsStyle, fields, itemTypeName)
          : this.renderItemList(items, itemsStyle, fields, itemTypeName)}
      </>
    );

    return (
      <div className={rootClassNames.join(" ")} style={style}>
        <div className={classNames.containerLabel}>
          <span title={hints}>{label}</span>
          {items &&
            !!items.length && [
              <Icon
                key={`${label}ToggleContent`}
                iconName={collapsed ? "ChevronDown" : "ChevronUp"}
                className={classNames.collapsedIcon}
                title="Toggle Content"
                onClick={() => this.setState({ collapsed: !collapsed })}
              />,
              <Icon
                key={`${label}ToggleDisplayView`}
                iconName={isTabView ? "List" : "Tab"}
                className={classNames.displayViewIcon}
                title={isTabView ? "Switch to List View" : "Switch to Tab View"}
                onClick={() =>
                  this.setState({
                    itemDisplayView: isTabView ? ItemDisplayView.list : ItemDisplayView.tab,
                  })
                }
              />,
            ]}
          <Icon iconName="Add" title="Add New Item" className={classNames.addItemIcon} onClick={this.onItemAdd} />
          {isTabView && items?.length > 0 && (
            <SearchBox
              className={classNames.containerSearch}
              value={searchText}
              placeholder={`Search in ${label}`}
              onChange={this.onSearchChange}
            />
          )}
        </div>
        {!collapsed && <span className={classNames.subTitle}>{hints}</span>}
        {itemsUi}
      </div>
    );
  }

  renderItemTabs(
    items: any[],
    itemsDisplayed: any[],
    itemsStyle: CSSProperties,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) {
    const { field, parentDataContext } = this.props;
    const { selectedKey, searchText } = this.state;

    return (
      <Pivot
        className={classNames.pivot}
        linkSize="normal"
        style={itemsStyle}
        selectedKey={selectedKey}
        overflowBehavior="menu"
        onLinkClick={this.onTabSelect}
      >
        {items.map((item, index) => {
          const itemKey = getItemKey(searchText, itemTypeName, index);

          if (items !== itemsDisplayed && itemsDisplayed.indexOf(item) < 0) {
            return null;
          }

          return (
            <PivotItem
              key={itemKey}
              itemKey={itemKey}
              className={classNames.pivotItem}
              headerText={
                (field.customTabHeader && field.customTabHeader(parentDataContext, itemTypeName, index)) ||
                `${itemTypeName} #${index + 1}`
              }
            >
              <div className={classNames.items}>{this.renderItemFields(items, item, index, fields, itemTypeName)}</div>
            </PivotItem>
          );
        })}
      </Pivot>
    );
  }

  renderItemList(
    items: any[],
    itemsStyle: CSSProperties,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) {
    return (
      <div
        className={`${classNames.items} ${classNames.content}`}
        style={itemsStyle}
        ref={(el) => (this.itemsElement = el)}
      >
        {items?.map ? (
          items.map((item, index) => this.renderItemFields(items, item, index, fields, itemTypeName))
        ) : (
          <>{items}</>
        )}
      </div>
    );
  }

  renderItemFields = (
    items: any[],
    item,
    index: number,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) => {
    const { itemDisplayView, searchText } = this.state,
      isTabView = itemDisplayView === ItemDisplayView.tab;
    const { field, parentDataContext } = this.props;
    let fieldsToUse = resolveFields(fields, parentDataContext, item);

    let itemFields = updateItemFieldValues(fieldsToUse, item),
      key = getItemKey(searchText, itemTypeName, index);

    var dataContext: IDataContext = {
      context: item,
      parentDataContext,
      field,
      key,
      onFieldValueChange: (fieldName, newValue) => this.onItemFieldValueChange(index, fieldName, newValue),
    };

    return (
      <div key={key} className={classNames.itemFields}>
        <div className={`${classNames.dataField} ${classNames.itemLabelField}`}>
          <div className={classNames.itemLabel}>
            {(field.customTitle && field.customTitle(parentDataContext, itemTypeName, index)) ||
              `${itemTypeName} #${index + 1}`}
          </div>
          <div className={classNames.itemActions}>
            <Icon
              className={classNames.actionIcon}
              iconName="Delete"
              title="Delete Item"
              onClick={() => this.onItemDelete(index)}
            />
            {index > 0 && (
              <Icon
                className={classNames.actionIcon}
                iconName={isTabView ? "Back" : "Up"}
                title={isTabView ? "Move Back" : "Move Up"}
                onClick={() => this.onItemMove(index, -1)}
              />
            )}
            {index < items.length - 1 && (
              <Icon
                className={classNames.actionIcon}
                iconName={isTabView ? "Forward" : "Down"}
                title={isTabView ? "Move Forward" : "Move Down"}
                onClick={() => this.onItemMove(index, 1)}
              />
            )}
          </div>
        </div>
        <div className={classNames.fields}>
          <DataFormContent
            field={field}
            fields={itemFields}
            context={item}
            parentDataContext={dataContext}
            onFieldValueChange={(fieldName, newValue) => this.onItemFieldValueChange(index, fieldName, newValue)}
            id={key}
          />
        </div>
      </div>
    );
  };

  onItemFieldValueChange = (itemIndex: number, fieldName: string, newValue: any) => {
    const { onFieldValueChange, fieldName: itemFieldName, items } = this.props;
    const newItems = items.map((item, index) => {
      if (index === itemIndex) {
        if (newValue === "" || newValue === null || newValue === undefined) {
          delete item[fieldName];
          return item;
        } else {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
      }

      return item;
    });

    onFieldValueChange(itemFieldName, newItems);
  };

  onItemMove = (itemIndex: number, indexChange?: number) => {
    const { onFieldValueChange, fieldName, items, itemTypeName } = this.props;
    const { itemDisplayView, searchText } = this.state;

    let newItems = items.slice(),
      targetItems = newItems.splice(itemIndex, 1),
      newIndex = itemIndex + indexChange;

    indexChange && newItems.splice(newIndex, null, targetItems[0]);

    onFieldValueChange(fieldName, newItems);

    if (itemDisplayView === ItemDisplayView.tab) {
      if (!indexChange && itemIndex > 0) {
        this.setState({ selectedKey: getItemKey(searchText, itemTypeName, itemIndex - 1) });
      } else {
        this.setState({ selectedKey: getItemKey(searchText, itemTypeName, newIndex) });
      }
    }
  };

  onItemAdd = () => {
    const { onFieldValueChange, fieldName, items } = this.props;

    let newItems = items ? items.slice() : [];

    newItems.push({});

    onFieldValueChange(fieldName, newItems);
    this.state.collapsed && this.setState({ collapsed: false });
  };

  onItemDelete = (itemIndex: number) => this.onItemMove(itemIndex);

  onTabSelect = (selectedItem) => {
    const selectedKey = selectedItem?.props?.itemKey;
    this.setState({ selectedKey });

    const { onItemSelected, fieldName } = this.props;

    if (onItemSelected) {
      onItemSelected(fieldName, selectedItem);
    }
  };

  onSearchChange = (ev, newValue: string) => {
    this.setState({ searchText: newValue?.trim().toLowerCase() });
  };
}

export default ItemContainer;

const updateItemFieldValues = (fields: IField[], item): IField[] => {
  return fields.map((field) => {
    if (field.fieldType === FieldType.container && field.fields && typeof field.fields === "object") {
      return {
        ...field,
        fields: updateItemFieldValues(field.fields, item),
      };
    }
    return {
      // Having it this order causes `value` to be overridden if specified in the field definition
      // However changing the order causes a regression in some dropdowns, including dataSourceType in report #5
      ...field,
      value: item[field.fieldName],
    };
  });
};

const getItemKey = (searchText: string, itemTypeName: string, index: number): string =>
  `${itemTypeName}-${searchText?.replace(/[^a-zA-Z0-9]/g, "") || ""}-${index}`;
