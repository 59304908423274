import { IConfigItemEditProps } from "./ConfigItemEdit";
import { ICommandBarItemProps, ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { liveOnlyLinks } from "../../../app/LeftNav.helper";
import { parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { Dictionary, IConfigEnvironment } from "../../../app/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

export const getEnvironmentCommandBarButton = (
  environments: Dictionary<IConfigEnvironment>,
  defaultEnvironment: string,
  onEnvirnomentChange: (envirnoment: string) => void,
  selectedEnvironment: string,
  environmentsLoaded: string[]
) => {
  const rightEnvironmentOptions: ICommandBarItemProps[] = [];
  environments &&
    Object.keys(environments)?.forEach((key) => {
      const environment: IConfigEnvironment = environments[key];
      if (environmentsLoaded.includes(environment.name) && environment.writeAccess) {
        rightEnvironmentOptions.push({
          key: key,
          name: environment.name,
          canCheck: true,
          checked: selectedEnvironment === environment.name,
          onClick: () => onEnvirnomentChange(environment.name),
        });
      }
    });

  if (!rightEnvironmentOptions.length) {
    rightEnvironmentOptions.push({
      key: "Loading",
      name: "Loading...",
      canCheck: false,
    });
  }

  const environment = selectedEnvironment ? selectedEnvironment : defaultEnvironment;

  if (environment) {
    return {
      key: "environment",
      name: `Environment: ${environment}`,
      iconProps: {
        iconName: "Cloud",
      },
      subMenuProps: {
        items: rightEnvironmentOptions,
      },
    };
  } else {
    return null;
  }
};

export const getCommandBarProps = (
  props: IConfigItemEditProps,
  onSaveChanges,
  onCancelChanges,
  onRefreshData,
  onDuplicateItem,
  onSavePublishChanges,
  onControlRun,
  onControlView,
  addToolbarItems?: ICommandBarItemProps[]
): ICommandBarProps => {
  const {
    isSmallScreen,
    saving,
    loading,
    configValue,
    configValueOriginal,
    configItem,
    userId,
    isSuperAdmin,
    isStaging,
    leftNavUrl,
    configItemType,
  } = props;
  const owner = configItem && configItem.owner;
  const editors = configItem && configItem.editors;
  const isDirty = configValue !== configValueOriginal;
  const isProcessing = saving || loading;
  const canParse = !!configItem;
  let canSave = isSuperAdmin || (owner && owner.indexOf(userId) >= 0) || (editors && editors.indexOf(userId) >= 0);

  if (!canSave) {
    const origConfigItem = parseJsonSafe(configValueOriginal);
    // Check if the current user is part of the original owner/editors.
    const origOwner = origConfigItem && origConfigItem.owner,
      origEditors = origConfigItem && origConfigItem.editors;

    canSave =
      isSuperAdmin ||
      (origOwner && origOwner.indexOf(userId) >= 0) ||
      (origEditors && origEditors.indexOf(userId) >= 0);
  }

  const allowAdminActions = isStaging || liveOnlyLinks.indexOf(leftNavUrl) >= 0;

  const commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [
      {
        key: "refresh",
        name: "Refresh",
        title: "Refresh page with latest data",
        iconProps: {
          iconName: "Refresh",
        },
        iconOnly: isSmallScreen,
        disabled: isProcessing,
        onClick: onRefreshData,
      },
      {
        key: "cancel",
        name: "Cancel",
        title: "Cancel changes",
        iconProps: {
          iconName: "Cancel",
        },
        iconOnly: isSmallScreen,
        disabled: isProcessing,
        onClick: onCancelChanges,
      },
    ],
  };

  if (canSave && allowAdminActions) {
    if (configValueOriginal) {
      commandBarProps.farItems = [
        {
          key: "duplicate",
          name: "Duplicate",
          title: "Duplicate this item",
          iconProps: {
            iconName: "Copy",
          },
          iconOnly: isSmallScreen,
          disabled: isProcessing,
          onClick: onDuplicateItem,
        },
        ...commandBarProps.farItems,
      ];
    }

    if (configItemType === ConfigItemType.Controls) {
      if (configItem?.isManaged) {
        commandBarProps.farItems = [
          {
            key: "run",
            name: "Run",
            title: "Run this control on demand",
            iconProps: {
              iconName: "Play",
            },
            iconOnly: isSmallScreen,
            disabled: isProcessing,
            onClick: onControlRun,
          },
          ...commandBarProps.farItems,
        ];
      }

      commandBarProps.farItems.unshift({
        key: "view",
        name: "View",
        title: "View this control details",
        iconProps: {
          iconName: "View",
        },
        iconOnly: isSmallScreen,
        disabled: isProcessing,
        onClick: onControlView,
      });
    }

    // Only support Save & Publish for non-sox items because sox items require a different person to do publish.
    if (isStaging && !configItem?.soxCompliance) {
      commandBarProps.farItems = [
        {
          key: "savepublish",
          name: "Save & Publish",
          title: "Save changes & publish to live mode",
          iconProps: {
            iconName: "Save",
          },
          iconOnly: isSmallScreen,
          disabled: isProcessing || !isDirty || !canParse,
          onClick: onSavePublishChanges,
        },
        ...commandBarProps.farItems,
      ];
    }

    commandBarProps.farItems = [
      {
        key: "save",
        name: "Save",
        title: "Save changes",
        iconProps: {
          iconName: "Save",
        },
        iconOnly: isSmallScreen,
        disabled: isProcessing || !isDirty || !canParse,
        onClick: onSaveChanges,
      },
      ...commandBarProps.farItems,
    ];
  }

  if (addToolbarItems?.length) {
    commandBarProps.farItems = [...addToolbarItems, ...commandBarProps.farItems];
  }

  return commandBarProps;
};
