import React from "react";
import { IField, FieldType, IDataContext } from "./interfaces";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Checkbox, ICheckboxProps } from "@fluentui/react/lib/Checkbox";
import { Icon } from "@fluentui/react/lib/Icon";
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";
import { DatePicker } from "@fluentui/react/lib/DatePicker";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import FieldContainer from "./FieldContainer";
import ItemContainer from "./ItemContainer";
import ContextField from "./ContextField";
import DateTextField from "./DateTextField";
import TextFieldContent from "./TextFieldContent";
import RichTextFieldContent from "./RichTextFieldContent";
import Editor from "@monaco-editor/react";
import { evalCondition, getValueOrDefault, caseInsensitiveCompare } from "../../utilities/miscHelper";
import classNames from "./DataForm.module.scss";
import moment from "moment";

const textArrayRegEx = "^[a-zA-Z0-9@.,_+!@#$%\\- ]*$";
const numberRegEx = "^-?[0-9]*\\.?[0-9]*$";

export interface IDataFormContentProps {
  fields: IField[];
  field?: IField;
  id?: string;
  context?: object;
  parentDataContext?: IDataContext;
  style?: React.CSSProperties;
  onFieldValueChange: (fieldName: string, newValue: any) => void;
  onErrorChange?: (fieldName: string, errorText: string, errors) => void;
  onItemSelected?: (fieldName: string, value: any) => void;
}

export interface IDataFormContentState {
  errors: any;
  showFullEditor: boolean;
  editorValue?: string;
  editorTitle?: string;
  editorField?: IField;
}

const getAncestry = (dataContext: IDataContext, ancestry?: string, value?: string) => {
  const { field, context } = dataContext;
  const fieldName = field?.fieldName;
  const currentValue = value || (typeof (context && context[fieldName]) === "string" ? context[fieldName] : "");
  const nextValue =
    context["name"] || context["id"] || typeof context[Object.keys(context)[0]] === "string"
      ? context[Object.keys(context)[0]]
      : "";
  const newPath = currentValue ? `${fieldName}[${currentValue}]` : fieldName;
  const fullPath = ancestry ? `${newPath}.${ancestry}` : newPath;
  let nextAncestor = dataContext?.parentDataContext;
  let safeguardCounter = 5;
  while (
    safeguardCounter > 0 &&
    nextAncestor?.parentDataContext?.field &&
    (!nextAncestor.field?.fieldName || nextAncestor.field?.fieldName === fieldName)
  ) {
    safeguardCounter--;
    nextAncestor = nextAncestor.parentDataContext;
  }
  if (nextAncestor?.field) {
    return getAncestry(nextAncestor, fullPath, nextValue);
  } else {
    return fullPath;
  }
};

export const resolveFields = (
  fields: IField[] | ((dataContext: IDataContext) => IField[]),
  dataContext: IDataContext,
  context?
) => {
  let returnFields: IField[] = null;
  const dataContextToUse = { ...dataContext };
  if (fields) {
    if (typeof fields === "function") {
      if (context) {
        dataContextToUse.context = context;
      }
      returnFields = fields(dataContextToUse);
    } else {
      returnFields = fields;
    }
  }
  return returnFields;
};

export class DataFormContent extends React.Component<IDataFormContentProps, IDataFormContentState> {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      showFullEditor: false,
    };
  }

  render() {
    const {
      fields,
      onFieldValueChange: onFieldValueChangeCallback,
      id,
      context = null,
      onItemSelected,
      parentDataContext: grandparentDataContext,
    } = this.props;
    const { errors, showFullEditor, editorValue, editorTitle, editorField } = this.state;

    if (!fields || !fields.length) return null;

    const getDefaultValue = (field: IField, dataContext) => {
      return typeof field.defaultValue === "function" ? field.defaultValue(dataContext) : field.defaultValue || "";
    };

    var content = fields.map((field, index) => {
      const {
        fieldName = "",
        fieldType,
        fieldProps = {},
        className,
        style,
        width,
        valueWidth,
        fillWidth = true,
        height,
        label,
        lastInRow,
        firstInRow,
        doNotExpandWidth,
        hints,
        visible,
        required,
        allowFullEditor = fieldType === FieldType.json || fieldType === FieldType.html,
        multiSelect,
        onValueChange,
      } = field;

      const value = getValueOrDefault(field.value || (context && context[fieldName]), "", false);
      const key = (id + fieldName + index).replaceAll(" ", "");

      const parentDataContext: IDataContext =
        context === grandparentDataContext?.context
          ? grandparentDataContext
          : {
              context,
              parentDataContext: grandparentDataContext,
              field,
              key,
            };

      const onFieldValueChange = (field: IField | string, newValue: any) => {
        const updatedValue = (onValueChange && onValueChange(parentDataContext, newValue)) || newValue;
        if (onFieldValueChangeCallback) {
          onFieldValueChangeCallback(typeof field === "string" ? field : field.fieldName, updatedValue);
        } else {
          this.onFieldValueChange(field as any, updatedValue);
        }
      };
      parentDataContext.onFieldValueChange = onFieldValueChange;

      const defaultValue = getDefaultValue(field, parentDataContext);

      const isContainer =
          fieldType === FieldType.container ||
          fieldType === FieldType.contextContainer ||
          fieldType === FieldType.context ||
          fieldType === FieldType.items,
        isVisible = evalCondition(visible, context, parentDataContext),
        fieldStyle = {
          ...style,
          width: !width && isContainer ? "100%" : width,
          height,
          flex: lastInRow && fillWidth ? "1 1 auto" : undefined,
          display: lastInRow && fillWidth ? "block" : undefined,
        },
        valueStyle = {
          width: valueWidth,
        };

      if (isVisible === false) {
        return null;
      }

      const fieldClassName = [classNames.dataField, className];
      const finalValue = fieldType === FieldType.json && typeof value !== "string" ? JSON.stringify(value) : value;

      lastInRow && fieldClassName.push(classNames.lastInRow);
      doNotExpandWidth && fieldClassName.push(classNames.doNotExpandWidth);
      fieldType === FieldType.context && fieldClassName.push(classNames.contextField);

      const isRequired = evalCondition(required, context, parentDataContext);
      const isCollapsed = evalCondition(field.collapsed, context, parentDataContext);

      // Optional extra props to pass through to the input element
      const inputProps: ICheckboxProps["inputProps"] = {
        ...{ "aria-labelledby": key },
      };

      let errorText = "";

      errorText = this.getErrorText(field, finalValue, parentDataContext);

      const error = errorText || (errors && errors[fieldName]);

      const idForElement = `IdElem${key}`;
      const idForLabel = `IdLabel${key}`;
      const labelElement = (
        <label id={idForLabel} htmlFor={idForElement}>
          {label}
        </label>
      );
      const fieldPropsWithLabel = { ...fieldProps, "aria-labelledby": idForLabel };

      let fieldContent = (
        <div key={key} className={fieldClassName.join(" ")} style={fieldStyle}>
          {label && !isContainer && fieldType !== FieldType.button && (
            <div className={classNames.fieldLabel} title={isRequired && "Required field"}>
              {labelElement}
              {isRequired && <span className={classNames.required} />}
              {hints && <Icon className={classNames.hintsIcon} iconName="Info" title={hints} />}
              {allowFullEditor && (
                <Icon
                  className={classNames.editorIcon}
                  iconName="WindowEdit"
                  title="Toggle Full View Editor"
                  onClick={() =>
                    this.setState({
                      showFullEditor: true,
                      editorValue: finalValue,
                      editorField: { ...field, value: finalValue },
                      editorTitle: `Edit ${label} field`,
                    })
                  }
                />
              )}
            </div>
          )}
          <div className={classNames.fieldValue}>
            {(fieldType === undefined ||
              fieldType === FieldType.html ||
              fieldType === FieldType.json ||
              fieldType === FieldType.text ||
              fieldType === FieldType.textArray ||
              fieldType === FieldType.number) && (
              <TextFieldContent
                id={idForElement}
                value={getValueOrDefault(finalValue, "", true)}
                field={field}
                fieldProps={fieldPropsWithLabel}
                onChange={(field, newValue) => this.onFieldValueChange(field, newValue)}
                key={`TextField-${key}`}
              />
            )}
            {fieldType === FieldType.displayOnly && <div className={classNames.displayOnly}>{value}</div>}
            {fieldType === FieldType.enumeration && (
              <Dropdown
                id={idForElement}
                options={this.getDropdownValues(field, value, parentDataContext)}
                selectedKey={multiSelect ? undefined : value === undefined ? defaultValue : value}
                selectedKeys={multiSelect && value ? (Array.isArray(value) ? value : value.split(",")) : undefined}
                multiSelect={multiSelect}
                style={valueStyle}
                placeholder="[None Selected]"
                onChange={(ev, option) => this.onDropdownValueChange(field, option.key as string)}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.richText && (
              <RichTextFieldContent
                id={idForElement}
                value={value}
                field={field}
                onChange={(ev, checked) => onFieldValueChange(field, checked)}
                fieldProps={fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.boolean && (
              <Checkbox
                id={idForElement}
                className={classNames.checkbox}
                checked={value}
                inputProps={inputProps}
                onChange={(ev, checked) => this.onFieldValueChange(field, checked)}
              />
            )}
            {fieldType === FieldType.date && (
              <DatePicker
                id={idForElement}
                className={classNames.datePicker}
                value={value && new Date(value)}
                style={valueStyle}
                onSelectDate={(date: Date) => this.onFieldValueChange(field, date.toISOString())}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.dateMonth && (
              <DatePicker
                id={idForElement}
                className={classNames.datePicker}
                value={value && new Date(value)}
                style={valueStyle}
                onSelectDate={(date: Date) => this.onFieldValueChange(field, new Date(date.setDate(1)).toISOString())}
                formatDate={(date: Date) => moment(date).format("YYYY MMM")}
                calendarProps={{
                  strings: null,
                  isDayPickerVisible: false,
                  isMonthPickerVisible: true,
                  showMonthPickerAsOverlay: true,
                }}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.dateText && (
              <DateTextField
                id={idForElement}
                className={classNames.dateText}
                value={value}
                style={valueStyle}
                onChange={(value) => onFieldValueChange(field, value)}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.container && field.fields && (
              <FieldContainer
                id={idForElement}
                label={label}
                field={field}
                fields={resolveFields(field.fields, parentDataContext, finalValue)}
                collapsible={evalCondition(field.collapsible, context, parentDataContext)}
                collapsed={evalCondition(field.collapsed, context, parentDataContext)}
                compact={field.compact}
                context={context}
                parentDataContext={parentDataContext}
                style={valueStyle}
                onFieldValueChange={onFieldValueChange}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.contextContainer && field.fields && (
              <FieldContainer
                id={idForElement}
                label={label}
                field={field}
                fields={resolveFields(field.fields, parentDataContext && parentDataContext[fieldName], finalValue)}
                collapsible={evalCondition(field.collapsible, context, parentDataContext)}
                collapsed={evalCondition(field.collapsed, context && context[fieldName], parentDataContext)}
                compact={field.compact}
                context={context && context[fieldName]}
                parentDataContext={parentDataContext && parentDataContext[fieldName]}
                style={valueStyle}
                onFieldValueChange={onFieldValueChange}
                {...fieldPropsWithLabel}
              />
            )}

            {fieldType === FieldType.items && field.fields && (
              <ItemContainer
                label={label}
                fieldName={fieldName}
                field={field}
                fields={field.fields}
                hints={hints}
                items={typeof value === "function" ? value(parentDataContext) : value}
                itemTypeName={field.itemTypeName}
                collapsed={isCollapsed}
                compact={field.compact}
                style={valueStyle}
                itemDisplayView={field.itemDisplayView}
                onFieldValueChange={onFieldValueChange}
                onItemSelected={onItemSelected}
                parentDataContext={parentDataContext}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.context && field.fields && (
              <ContextField
                {...this.props}
                id={idForElement}
                context={context && context[fieldName]}
                field={field}
                fields={resolveFields(field.fields, parentDataContext, finalValue)}
                style={valueStyle}
                onFieldValueChange={(childFieldName, newValue) => {
                  let field = (context && context[fieldName]) || {};
                  field[childFieldName] = newValue;
                  onFieldValueChange(fieldName, field);
                }}
                parentDataContext={parentDataContext}
                {...fieldPropsWithLabel}
              />
            )}
            {fieldType === FieldType.customHtml && field.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: typeof field.value === "function" ? field.value(parentDataContext) : field.value.toString(),
                }}
              />
            )}
            {fieldType === FieldType.button && field.onClick && (
              <PrimaryButton onClick={() => field.onClick(parentDataContext.context, field)}>
                {field.label}
              </PrimaryButton>
            )}
          </div>
          {error && (
            <div className={classNames.fieldError} title={error}>
              {error}
            </div>
          )}
        </div>
      );

      if (lastInRow) {
        return [fieldContent, <div key="lastInRow" className={classNames.lastInRowField} />];
      } else if (firstInRow) {
        return [<div key="lastInRow" className={classNames.lastInRowField} />, fieldContent];
      }
      return fieldContent;
    });

    if (showFullEditor) {
      var editorFinalValue = editorValue?.toString(),
        isJsonContent = editorField && editorField.fieldType === FieldType.json,
        editorError = null;

      if (isJsonContent) {
        try {
          editorFinalValue = JSON.stringify(JSON.parse(editorValue), null, 2);
        } catch {
          editorError = "Invalid JSON format. This field expects well-formed JSON data.";
        }
      }

      content.push(
        <Dialog
          key="full-editor"
          hidden={!showFullEditor}
          onDismiss={this.onEditorCancel}
          dialogContentProps={{ title: editorTitle, className: classNames.dialogContent }}
          modalProps={{ className: classNames.editorModal, layerProps: { eventBubblingEnabled: true } }}
          minWidth={window.innerWidth * 0.8}
        >
          <div className={classNames.editorContent} style={{ height: window.innerHeight * 0.6 }}>
            <Editor
              language={isJsonContent && "json"}
              value={editorFinalValue}
              onChange={this.onEditorChange}
              onMount={(editor) => editor.focus()}
            />
          </div>
          {editorError && (
            <div className={classNames.fieldError} title={editorError}>
              {editorError}
            </div>
          )}
          <DialogFooter>
            <DefaultButton text="Cancel" onClick={this.onEditorCancel} />
            <PrimaryButton text="Done" onClick={this.onEditorDismiss} />
          </DialogFooter>
        </Dialog>
      );
    }

    return content;
  }

  hasErrors = () => {
    let errors = this.getErrors();
    return Object.keys(errors).filter((fieldName) => !!errors[fieldName]).length > 0;
  };

  onDropdownValueChange = (field: IField, newValue: any) => {
    const { multiSelect, value } = field;

    let newVal = newValue;

    if (multiSelect) {
      if (value) {
        let values = typeof value === "string" ? value.split(",") : value;
        if (Array.isArray(values)) {
          const valueIndex = values.indexOf(newValue);

          if (valueIndex >= 0) {
            values.splice(valueIndex, 1);
          } else {
            values.push(newValue);
          }
        }

        newVal = values;
      } else if (!Array.isArray(newValue)) {
        newVal = [newValue];
      }
    }

    this.onFieldValueChange(field, newVal);
  };

  onFieldValueChange = (field: IField, newValue) => {
    const { onFieldValueChange } = this.props;
    const { fieldType, value: oldValue } = field;

    let errorText = this.validateErrorState(field, newValue);

    if (fieldType === FieldType.number) {
      newValue = parseFloat(newValue);
      let regExObject = new RegExp(numberRegEx);
      if (newValue && !newValue.toString().match(regExObject)) {
        if (oldValue?.toString().match(regExObject)) {
          newValue = oldValue;
        } else {
          newValue = "";
        }
      } else if (errorText) {
        newValue = oldValue;
      }
    } else if (fieldType === FieldType.json && typeof newValue === "string") {
      try {
        newValue = JSON.parse(newValue);
      } catch {}
    } else if (fieldType === FieldType.textArray) {
      newValue = Array.isArray(newValue) ? newValue : newValue === "" ? [] : newValue?.split && newValue.split(",");
    }

    onFieldValueChange && onFieldValueChange(field.fieldName, newValue);
  };

  validateErrorState = (field: IField, newValue): string => {
    const { fieldName } = field;
    const { parentDataContext } = this.props;

    const errorText = this.getErrorText(field, newValue, parentDataContext);
    this.setErrorState(fieldName, errorText);

    return errorText;
  };

  findAndUpdateOptions = (options: IDropdownOption[], selectedValue: string, caseInsensitive: boolean = false) => {
    return options?.find((valueToCheck) => {
      if (valueToCheck.key === selectedValue) {
        return true;
      } else if (caseInsensitive && caseInsensitiveCompare(valueToCheck.key, selectedValue)) {
        // Set key to match case so that it correctly appears in dropdown
        valueToCheck.key = selectedValue;
        return true;
      }
      return false;
    });
  };

  getDropdownValues = (field: IField, value: any, parentDataContext: IDataContext) => {
    const options = typeof field.options === "function" ? field.options(parentDataContext) : field.options;
    if (value && options) {
      if (field.multiSelect) {
        const selected: string[] = Array.isArray(value) ? value : value.split(",");
        selected.forEach((selectedValue) => {
          const invalid = `${selectedValue} (invalid)`;
          const invalidNode: IDropdownOption = { key: selectedValue, text: invalid, ariaLabel: invalid };
          if (selectedValue && !this.findAndUpdateOptions(options, selectedValue, field.caseInsensitive)) {
            options.push(invalidNode);
          }
        });
      } else {
        const invalid = `${value} (invalid)`;
        const invalidNode: IDropdownOption = { key: value, text: invalid, ariaLabel: invalid };
        if (!this.findAndUpdateOptions(options, value, field.caseInsensitive)) {
          options.push(invalidNode);
        }
      }
    }
    return options;
  };

  checkIfValuesAreInDropdown = (field: IField, newValue: any, parentDataContext: IDataContext) => {
    const options = typeof field.options === "function" ? field.options(parentDataContext) : field.options;
    let foundError = false;
    if (field.multiSelect) {
      const selected: string[] = Array.isArray(newValue) ? newValue : newValue.split(",");
      selected.forEach((selectedValue) => {
        if (!this.findAndUpdateOptions(options, selectedValue, field.caseInsensitive)) {
          foundError = true;
        }
      });
    } else {
      if (!this.findAndUpdateOptions(options, newValue, field.caseInsensitive)) {
        foundError = true;
      }
    }
    return !foundError;
  };

  getErrorText = (field: IField, newValue, parentDataContext: IDataContext): string => {
    const { fieldType, regEx, required, checkIfValid } = field;

    if (newValue) {
      const checkStatus = checkIfValid ? checkIfValid(parentDataContext, newValue) : null;
      if (checkStatus && !checkStatus.passed) {
        return checkStatus?.message;
      }
      if (regEx) {
        let regExObject = new RegExp(regEx);
        if (newValue && !newValue.toString().match(regExObject)) {
          return "Invalid data format. See the hints for the expected data format.";
        }
      }
      if (fieldType === FieldType.enumeration) {
        if (!this.checkIfValuesAreInDropdown(field, newValue, parentDataContext)) {
          return "Option specified is not valid.";
        }
      } else if (fieldType === FieldType.number) {
        let regExObject = new RegExp(numberRegEx);
        if (newValue && !newValue.toString().match(regExObject)) {
          return "Invalid number format. This field expects a number.";
        }
        if (field.minValue !== undefined && Number(newValue) < Number(field.minValue)) {
          return `Value cannot be less than ${field.minValue}.`;
        }
        if (field.maxValue !== undefined && Number(newValue) > Number(field.maxValue)) {
          return `Value cannot be greater than ${field.maxValue}.`;
        }
      } else if (fieldType === FieldType.json && typeof newValue === "string") {
        try {
          newValue = JSON.parse(newValue);
        } catch {
          return "Invalid JSON format. This field expects well-formed JSON data.";
        }
      } else if (fieldType === FieldType.textArray) {
        let regExObject = new RegExp(textArrayRegEx);
        if (newValue && !newValue.toString().match(regExObject)) {
          return "Invalid data format. This field expects comma-separated text values.";
        }
      }
    } else {
      const isRequired = evalCondition(required, newValue, parentDataContext);
      if (isRequired && (newValue === "" || newValue === null || newValue === undefined)) {
        return "This is a required field.";
      }
    }
    return null;
  };

  setErrorState = (fieldName: string, errorText: string) => {
    let errors = { ...this.state.errors };

    if (errors[fieldName] !== errorText) {
      if (errorText) {
        errors[fieldName] = errorText;
      } else {
        delete errors[fieldName];
      }

      this.setState({ errors });

      const { onErrorChange } = this.props;
      onErrorChange && onErrorChange(fieldName, errorText, errors);
    }
  };

  getErrors = () => {
    let errors = {};
    const { field = null, fields, context } = this.props;

    const parentDataContext: IDataContext = {
      context,
      parentDataContext: null,
      field,
      key: "root",
    };

    this.getErrorsByFields(fields, errors, parentDataContext);

    return errors;
  };

  getErrorsForItemsWithFieldGetter = (
    fieldGetter: (dataContext: IDataContext) => IField[],
    errors,
    parentDataContext: IDataContext,
    items?
  ) => {
    items.forEach((item) => {
      const dataContext = {
        ...parentDataContext,
        context: item,
      };
      const fields = fieldGetter(dataContext);

      errors = this.getErrorsByFields(fields, errors, parentDataContext, item);
    });
  };

  getErrorsByFields = (fields: IField[], errors, parentDataContext: IDataContext, value?) => {
    const valueContext = value || parentDataContext?.context;
    fields &&
      fields.forEach((field, index) => {
        const dataContext: IDataContext = {
          context: valueContext,
          parentDataContext,
          field,
          key: `${index}`,
        };

        // Check for visibility. If the field is not visible, ignore error checking.
        var isFieldVisible = evalCondition(field.visible, valueContext, parentDataContext);

        if (isFieldVisible) {
          if (field.fieldType === FieldType.container && valueContext) {
            // For container field, use the same parent value.
            errors = this.getErrorsByFields(
              resolveFields(field?.fields, parentDataContext, valueContext),
              errors,
              dataContext,
              valueContext
            );
          } else if (valueContext && valueContext.forEach) {
            // For items field, get errors for each item.
            valueContext.forEach((item) => {
              dataContext.context = item;
              errors = this.getErrorsByField(field, errors, dataContext, item[field.fieldName]);
            });
          } else if (valueContext) {
            // This is for the case of a single item of an items field.
            errors = this.getErrorsByField(field, errors, dataContext, valueContext[field.fieldName]);
          } else {
            // This is the basic, top-level field with value.
            dataContext.context = field.value || valueContext;
            errors = this.getErrorsByField(field, errors, dataContext, field.value || valueContext);
          }
        }
      });

    return errors;
  };

  getErrorsByField = (field: IField, errors, parentDataContext: IDataContext, value) => {
    const errorText = this.getErrorText(field, value, parentDataContext);

    if (errorText) {
      let fullPath = getAncestry(parentDataContext);
      errors[fullPath] = errorText;
    }

    if (field?.fields) {
      const items =
        (field.fieldType === FieldType.items && value && value.length) || typeof value === "object" ? value : null;
      if (field.fieldType === FieldType.items && typeof field.fields === "function") {
        errors = this.getErrorsForItemsWithFieldGetter(field.fields, errors, parentDataContext, items);
      } else {
        items &&
          (errors = this.getErrorsByFields(
            resolveFields(field.fields, parentDataContext, value),
            errors,
            parentDataContext,
            items
          ));
      }
    }
    return errors;
  };

  onEditorChange = (newValue: string) => {
    const { editorField } = this.state;

    this.setState({ editorValue: newValue });
    this.onFieldValueChange(editorField, newValue);
  };

  onEditorDismiss = () => this.setState({ showFullEditor: false });

  onEditorCancel = () => {
    const { editorField } = this.state;
    const { value: oldValue } = editorField;
    this.onFieldValueChange(editorField, oldValue);
    this.onEditorDismiss();
  };
}

export default DataFormContent;
