import React from "react";
import { DataForm, IField } from "../../../shared/components/DataForm";

export interface IConfigItemEditFormProps {
  configItem: any;
  fields: IField[];
  configItemPropName?: string;
  editConfigItem: (configValue: string) => void;
  onFormItemSelected?: (fieldName: string, value: any) => void;
}

export class ConfigItemEditForm extends React.Component<IConfigItemEditFormProps> {
  private dataForm: React.RefObject<any> = React.createRef();

  render() {
    const { configItem, fields, onFormItemSelected } = this.props;

    if (!configItem) return null;

    return (
      <DataForm
        fields={fields}
        onFieldValueChange={this.onFieldValueChange}
        onItemSelected={onFormItemSelected}
        ref={this.dataForm}
        context={configItem}
      />
    );
  }

  onFieldValueChange = (fieldName: string, newValue: any) => {
    const { configItem, editConfigItem, configItemPropName: propName } = this.props;

    if (configItem) {
      let configObject = propName ? configItem[propName] : configItem;

      if (newValue === "" || newValue === null || newValue === undefined) {
        delete configObject[fieldName];
      } else {
        configObject[fieldName] = newValue;
      }

      editConfigItem && editConfigItem(JSON.stringify(configItem, null, 2));
    }
  };

  getErrors = () => this.dataForm && this.dataForm.current && this.dataForm.current.getErrors();
}

export default ConfigItemEditForm;
