import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultControl, getGeneralFields, getOutputFields } from "./MyControlEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IState } from "../../../reducers/interfaces";
import { IIcMConnector, IEntityView } from "../../common/interfaces";
import { ConfigItemType } from "../../../api/radarApi";
import TestDataSourceModal from "../common/TestDataSourceModal";
import { IField } from "../../../shared/components/DataForm";
import { IAppState } from "../../../app/App";

export interface IMyControlEditProps {
  appState?: IAppState;
}

export interface IMyControlEditStateProps extends IConfigItemEditProps {
  icmConnectors?: IIcMConnector[];
  entityViews?: IEntityView[];
  serviceContentConfigItems?: any[];
  controlMetadata?: any;
}

export interface IMyControlEditState {
  showTestResultDialog?: boolean;
  dataSource?: object;
}

export interface IMyControlEditDispatchProps extends IConfigItemEditDispatchProps {
  loadIcmConnectors?: (refresh?: boolean, doNotUpdateConfigValue?: boolean) => void;
  loadEntityViews?: () => void;
  loadServiceContentConfigItems?: (refresh?: boolean) => void;
  loadControlMetadata?: (refresh?: boolean) => void;
}

export class MyControlEdit extends React.Component<
  IMyControlEditProps & IMyControlEditStateProps & IMyControlEditDispatchProps & IMyControlEditState
> {
  private generalEditForm: React.RefObject<any> = React.createRef();
  private outputEditForm: React.RefObject<any> = React.createRef();

  state: IMyControlEditState = {
    showTestResultDialog: false,
    dataSource: undefined,
  };

  componentDidMount() {
    const {
      icmConnectors,
      entityViews,
      serviceContentConfigItems,
      controlMetadata,
      loadIcmConnectors,
      loadEntityViews,
      loadServiceContentConfigItems,
      loadControlMetadata,
    } = this.props;

    if (!icmConnectors?.length) {
      loadIcmConnectors(true, true);
    }

    if (!controlMetadata) {
      loadControlMetadata();
    }

    if (!entityViews?.length) {
      loadEntityViews();
    }

    if (!serviceContentConfigItems?.length) {
      loadServiceContentConfigItems();
    }
  }

  render() {
    const { configValue, editConfigItem, icmConnectors, entityViews, serviceContentConfigItems, controlMetadata } =
      this.props;
    const { showTestResultDialog, dataSource } = this.state;

    let configItem = null,
      entityTypes = [];

    entityViews &&
      entityViews.forEach((view) => {
        if (view.nodes && view.nodes.length) {
          view.nodes.forEach((node) => {
            entityTypes.indexOf(node.id) < 0 && entityTypes.push(node.id);
          });
        }
      });

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    if (!configItem) {
      configItem = {};
    }

    if (!configItem.outputConfig) {
      configItem.outputConfig = {};
    }

    if (!configItem.icmSettings) {
      configItem.icmSettings = [
        {
          autoIncidentOnFailure: configItem.autoIncidentOnFailure,
          icmConnectorId: configItem.icmConnectorId,
          icmTeamPublicId: configItem.icmTeamPublicId,
          icmRoutingId: configItem.icmRoutingId,
          icmEnvironment: configItem.icmEnvironment,
          icmKeywords: configItem.icmKeywords,
          icmIncidentType: configItem.icmIncidentType,
          defaultIncidentSeverity: configItem.defaultIncidentSeverity,
          maxIcMFailLineItem: configItem.maxIcMFailLineItem,
          newIncidentOnFailure: configItem.newIncidentOnFailure,
          useNameAsTitle: configItem.useNameAsTitle,
          icmTitleFieldName: configItem.icmTitleFieldName,
          icmSummaryFieldNames: configItem.icmSummaryFieldNames,
          icmTsgId: configItem.icmTsgId,
          icmTsgOutput: configItem.icmTsgOutput,
          icmTags: configItem.icmTags,
          customFields: configItem.customFields,
          createIcMOnLineItemLevel: configItem.createIcMOnLineItemLevel,
          lineItemIdFieldName: configItem.lineItemIdFieldName,
          lineItemTitleFieldName: configItem.lineItemTitleFieldName,
          maxIcMIncidents: configItem.maxIcMIncidents,
        },
      ];
    }

    const onTestDataSource = (dataSource, field: IField) => {
      if (field?.fieldProps && field.fieldProps["queryFieldName"]) {
        dataSource.query = dataSource[field.fieldProps["queryFieldName"]];
      }

      this.setState({ showTestResultDialog: true, dataSource });
    };

    return (
      <>
        <ConfigItemEdit
          {...this.props}
          configItem={configItem}
          configItemName="Control"
          configItemType={ConfigItemType.Controls}
          appInsightsPageName="MyControlEditPage"
          leftNavUrl={leftNavUrls.management.control}
          defaultConfigItem={defaultControl}
          getFieldErrors={this.getFieldErrors}
          pivotItems={[
            <PivotItem key="general" className={classNames.pivotItem} headerText="General">
              <ConfigItemEditForm
                configItem={configItem}
                fields={getGeneralFields(
                  configItem,
                  icmConnectors,
                  entityTypes,
                  serviceContentConfigItems,
                  controlMetadata,
                  onTestDataSource
                )}
                editConfigItem={editConfigItem}
                ref={this.generalEditForm}
              />
            </PivotItem>,
            <PivotItem key="output" className={classNames.pivotItem} headerText="Output">
              <ConfigItemEditForm
                configItem={configItem}
                fields={getOutputFields(configItem && configItem.outputConfig)}
                editConfigItem={editConfigItem}
                configItemPropName="outputConfig"
                ref={this.outputEditForm}
              />
            </PivotItem>,
          ]}
        />
        <TestDataSourceModal
          hidden={!showTestResultDialog}
          dataSource={dataSource}
          onDismiss={() => this.setState({ showTestResultDialog: false })}
        />
      </>
    );
  }

  getFieldErrors = () => {
    var generalEditFormErrors =
      this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
    var outputEditFormErrors =
      this.outputEditForm && this.outputEditForm.current && this.outputEditForm.current.getErrors();

    return { ...generalEditFormErrors, ...outputEditFormErrors };
  };
}

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  icmConnectors: state.modules.icm_connectors,
  entityViews: state.modules.entity_views,
  serviceContentConfigItems: state.modules.service_content_configs,
  controlMetadata: state.modules.control_metadata,
  loading: state.modules.loading_config || state.modules.loading_controls || state.modules.loading_control_results,
});

const mapDispatchToProps: IMyControlEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadControlConfig,
  updateConfigItem: actionCreator.updateControl,
  loadIcmConnectors: actionCreator.loadIcmConnectors,
  loadEntityViews: actionCreator.loadEntityViewConfigItems,
  loadServiceContentConfigItems: actionCreator.loadServiceContentConfigItems,
  loadControlMetadata: actionCreator.loadControlMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyControlEdit);
