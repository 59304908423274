import { IField, FieldType, IDataContext } from "../../../shared/components/DataForm";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { startCase } from "../../../shared/utilities/miscHelper";
import { IFmeCloseIssue, IOkr, IOkrValueOverride, ITeam, ITemplate } from "../../common/interfaces";
import { getMetricId } from "../../home/HealthDashboard.helper";
import { IHealthMetricGroup, IHealthMetricType } from "../../home/HealthDashboard.types";
import {
  checkIfUnique,
  getCommonStatsFields,
  getDisplayTypeOptions,
  getTileDataSourceFields,
  getTileFields,
  getValueChevronTypeOptions,
  getValueCompareTypeOptions,
} from "../common/helper";

export const defaultTeam: ITeam = {
  id: "",
};

export const getFields = (team: ITeam): IField[] => {
  if (!team) return null;

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "ID",
      value: team.id,
    },
    {
      fieldName: "name",
      label: "Team Name",
      value: team.name,
      required: true,
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: team.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: team.editors,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: team.description,
      lastInRow: true,
    },
    {
      fieldName: "reportId",
      fieldType: FieldType.number,
      label: "Report Id",
      value: team.reportId,
      width: "80px",
      valueWidth: "60px",
      hints:
        "Specific the Radar report ID where the report content would be displayed below the OKRs. Note: This field expects the report ID, not the report group ID.",
    },
    {
      fieldName: "sortOrder",
      fieldType: FieldType.number,
      label: "OKR Sort Order",
      value: team.sortOrder,
      width: "100px",
      valueWidth: "60px",
      hints: "A number to determine the sort order of the teams when displayed as OKR tabs.",
    },
    {
      fieldName: "ignoreOkrs",
      fieldType: FieldType.boolean,
      label: "Ignore OKRs",
      value: team.ignoreOkrs,
      hints: "Check to ignore OKRs data for this team.",
    },
    {
      fieldName: "ignoreFmeCloseIssues",
      fieldType: FieldType.boolean,
      label: "Ignore FME Close Issues",
      value: team.ignoreFmeCloseIssues,
      hints: "Check to ignore FME close issues for this team.",
    },
  ];
};

const getResultTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
  const result: IOkrValueOverride[] = dataContext?.context?.valueOverrides;
  return result[index]?.startTime || `Result #${index + 1}`;
};

const getOkrTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
  const okrs: IOkr[] = dataContext?.context?.okrs;
  return okrs[index]?.name || `OKR #${index + 1}`;
};

const getStatsCommonFields = (testDataSource: Function = undefined): IField[] => [
  ...getCommonStatsFields(),
  {
    fieldName: "dataSources",
    fieldType: FieldType.items,
    label: "Data Sources",
    itemTypeName: "Data Source",
    collapsed: false,
    fields: [
      ...getTileDataSourceFields(),
      {
        fieldName: "returnRowCount",
        fieldType: FieldType.boolean,
        label: "Return Row Count",
        width: "120px",
        hints: "Indicate if the result will return the 'rowCount' of the query instead.",
      },
      {
        fieldName: "rowCountFieldName",
        label: "Row Count Field Name",
        visible: `!!this.returnRowCount`,
        width: "160px",
        hints: "Optionally specific field name for the row count. By default, use 'rowCount'.",
      },
      {
        fieldName: "query",
        label: "Query",
        width: "100%",
        allowFullEditor: true,
        hints:
          "The query result is expected to return dataset of two columns: 'name' and 'value' with each row as one name-value pair.",
      },
      {
        fieldName: "runDelay",
        fieldType: FieldType.number,
        label: "Run Delay",
        width: "80px",
        valueWidth: "60px",
        hints: "Specify the number of seconds in delay before running the query.",
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
      },
    ],
  },
  {
    fieldName: "allDataSourcesMustComplete",
    fieldType: FieldType.boolean,
    label: "All Data Sources Must Complete",
    visible: "this.dataSources?.length > 1",
    lastInRow: true,
    hints:
      "All data sources must complete their queries successfully in order to store the resulting values in the database.",
  },
];

export const getOkrFields = (okrs: IOkr[]): IField => ({
  fieldName: "okrs",
  fieldType: FieldType.items,
  value: okrs,
  label: "OKRs",
  itemDisplayView: ItemDisplayView.list,
  itemTypeName: "OKR",
  collapsed: false,
  customTitle: getOkrTitle,
  customTabHeader: getOkrTitle,
  fields: [
    {
      fieldName: "name",
      label: "OKR Name",
      required: true,
      width: "480px",
    },
    {
      fieldName: "target",
      fieldType: FieldType.number,
      label: "Target",
      required: true,
      width: "80px",
      hints: "Target value for this OKR",
    },
    {
      fieldName: "valueDisplayType",
      fieldType: FieldType.enumeration,
      label: "Value Display Type",
      options: getDisplayTypeOptions(),
      hints: "What display type/format for values of this OKR",
    },
    {
      fieldName: "slaNote",
      label: "SLA Note",
      lastInRow: true,
      hints: "Note for SLA OKR",
    },
    {
      label: "Auto-Calculation",
      fieldType: FieldType.container,
      collapsible: true,
      compact: true,
      fields: [
        {
          fieldName: "id",
          label: "id",
          fieldType: FieldType.text,
          regEx: "^[a-zA-Z0-9]+$",
          hints: "Alphanumeric string used as a lookup id. Use camelCasing.",
          width: "260px",
          checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "okrs", "id"),
        },
        ...getStatsCommonFields(),
      ],
    },
    {
      label: "Advanced",
      fieldType: FieldType.container,
      collapsible: true,
      compact: true,
      fields: [
        {
          fieldName: "owner",
          label: "Owner",
          width: "100px",
          hints: "Optional. If not defined, the team owner will be used for this OKR.",
        },
        {
          fieldName: "targetDecimal",
          label: "Target Decimal",
          fieldType: FieldType.number,
          width: "100px",
          valueWidth: "40px",
          hints:
            "Optional. Specify decimal digit for the target value. Note: This field will be ignored if Target Override or Target Template field is used.",
        },
        {
          fieldName: "targetOverride",
          label: "Target Override",
          width: "100px",
          hints: "Optional. Text override for the target value.",
        },
        {
          fieldName: "targetTemplate",
          label: "Target Template",
          lastInRow: true,
          hints: "Optional. Template can be used to customize display for the target value.",
        },
      ],
    },
    {
      fieldName: "valueOverrides",
      fieldType: FieldType.items,
      label: "Result Value Overrides",
      itemTypeName: "Result",
      compact: true,
      itemDisplayView: ItemDisplayView.list,
      customTitle: getResultTitle,
      customTabHeader: getResultTitle,
      hints: "Allow OKR result values to be overridden",
      fields: [
        {
          fieldName: "startTime",
          fieldType: FieldType.dateText,
          label: "Start Time",
          hints: "Start time in ISO format",
        },
        {
          fieldName: "endTime",
          fieldType: FieldType.dateText,
          label: "End Time",
          hints: "End time in ISO format",
        },
        {
          fieldName: "value",
          fieldType: FieldType.number,
          label: "Value",
          width: "80px",
          hints: "Value for the current time period",
        },
        {
          fieldName: "lastValue",
          fieldType: FieldType.number,
          label: "Last Value",
          width: "80px",
          hints: "Value for the last time period",
        },
        {
          fieldName: "comment",
          label: "Comment",
          lastInRow: true,
        },
        {
          label: "Advanced",
          fieldType: FieldType.container,
          collapsible: true,
          compact: true,
          fields: [
            {
              fieldName: "valueDecimal",
              label: "Value Decimal",
              fieldType: FieldType.number,
              width: "140px",
              valueWidth: "40px",
              hints:
                "Optional. Specify decimal point for the value field. Note: This field will be ignored if Value Override or Value Template field is used.",
            },
            {
              fieldName: "valueOverride",
              label: "Value Override",
              width: "140px",
              hints: "Optional. Text override for the value field.",
            },
            {
              fieldName: "valueColor",
              label: "Value Color",
              width: "130px",
              valueWidth: "80px",
              hints: "Optional. Color override for the value field.",
            },
            {
              fieldName: "valueDisplayType",
              fieldType: FieldType.enumeration,
              label: "Value Display Type",
              options: getDisplayTypeOptions(),
              width: "180px",
              hints: "What display type/format for the value field.",
            },
            {
              fieldName: "valueTemplate",
              label: "Value Template",
              lastInRow: true,
              hints: "Optional. Template can be used to customize display for the value field.",
            },
            {
              fieldName: "lastValueDecimal",
              label: "Last Value Decimal",
              fieldType: FieldType.number,
              width: "140px",
              valueWidth: "40px",
              hints:
                "Optional. Specify decimal point for the last value field. Note: This field will be ignored if Last Value Override or Last Value Template field is used.",
            },
            {
              fieldName: "lastValueOverride",
              label: "Last Value Override",
              width: "140px",
              hints: "Optional. Text override for the last value field.",
            },
            {
              fieldName: "lastValueColor",
              label: "Last Value Color",
              width: "130px",
              valueWidth: "80px",
              hints: "Optional. Color override for the last value field.",
            },
            {
              fieldName: "lastValueDisplayType",
              fieldType: FieldType.enumeration,
              label: "Last Value Display Type",
              options: getDisplayTypeOptions(),
              width: "180px",
              hints: "What display type/format for the last value field.",
            },
            {
              fieldName: "lastValueTemplate",
              label: "Last Value Template",
              lastInRow: true,
              hints: "Optional. Template can be used to customize display for the last value field.",
            },
            {
              fieldName: "changeValueDecimal",
              label: "Change Value Decimal",
              fieldType: FieldType.number,
              width: "140px",
              valueWidth: "40px",
              hints:
                "Optional. Specify decimal point for the change value field. Note: This field will be ignored if Change Value Override or Change Value Template field is used.",
            },
            {
              fieldName: "changeValueOverride",
              label: "Change Value Override",
              width: "140px",
              hints: "Optional. Text override for the change value field.",
            },
            {
              fieldName: "changeValueColor",
              label: "Change Value Color",
              width: "130px",
              valueWidth: "80px",
              hints: "Optional. Color override for the change value field.",
            },
            {
              fieldName: "changeValueDisplayType",
              fieldType: FieldType.enumeration,
              label: "Change Value Display Type",
              options: getDisplayTypeOptions(),
              width: "180px",
              hints: "What display type/format for the change value field.",
            },
            {
              fieldName: "changeValueTemplate",
              label: "Change Value Template",
              lastInRow: true,
              hints: "Optional. Template can be used to customize display for the change value field.",
            },
            {
              fieldName: "changeValueChevronType",
              fieldType: FieldType.enumeration,
              label: "Change Value Chevron Type",
              options: getValueChevronTypeOptions(),
              width: "180px",
              valueWidth: "80px",
              hints: "Optional. Override the chevron type of the change value field.",
            },
            {
              fieldName: "valueCompareType",
              fieldType: FieldType.enumeration,
              label: "Value Compare Type",
              options: getValueCompareTypeOptions(),
              width: "180px",
              hints:
                "Optional. Specific how to compare values as good. Default to greater than or equal to target is good.",
            },
          ],
        },
      ],
    },
  ],
});

const getMetricChildTypeOptions = (dataContext: IDataContext, healthMetricTypes: IHealthMetricType[]) => {
  const selectedMetricTypeId = dataContext?.context?.metricType;

  let metricChildTypeOptions = [{ key: undefined, text: "" }];

  for (let i = 0; i < healthMetricTypes?.length; i++) {
    let metricType = healthMetricTypes[i];

    if (metricType.id === selectedMetricTypeId) {
      let metricChildTypes = metricType.metricChildTypes;

      if (metricChildTypes?.length) {
        for (let j = 0; j < metricChildTypes.length; j++) {
          const { id, name } = metricChildTypes[j];
          metricChildTypeOptions.push({ key: id, text: name });
        }
      }
    }
  }

  return metricChildTypeOptions;
};

const getMetricSubTypeOptions = (dataContext: IDataContext, healthMetricGroups: IHealthMetricGroup[]) => {
  const selectedMetricGroupId = dataContext?.context?.metricGroup;

  let metricSubTypeOptions = [{ key: undefined, text: "" }];

  for (let i = 0; i < healthMetricGroups?.length; i++) {
    let metricGroup = healthMetricGroups[i];

    if (metricGroup.id === selectedMetricGroupId) {
      let metricSubTypes = metricGroup.metricSubTypes;

      if (metricSubTypes?.length) {
        for (let j = 0; j < metricSubTypes.length; j++) {
          const { id, name } = metricSubTypes[j];
          metricSubTypeOptions.push({ key: id, text: name });
        }
      }
    }
  }

  return metricSubTypeOptions;
};

export const getHealthMetricsFields = (
  team: ITeam,
  standardTemplates: ITemplate[],
  healthMetricTypes: IHealthMetricType[],
  healthMetricGroups: IHealthMetricGroup[],
  testDataSource: Function
): IField[] => {
  let standardTemplateOptions = standardTemplates?.map((template) => ({ key: template.name, text: template.name }));
  standardTemplateOptions?.unshift({ key: "", text: "" });

  let metricTypeOptions = [{ key: undefined, text: "" }];

  for (let i = 0; i < healthMetricTypes?.length; i++) {
    let metricType = healthMetricTypes[i];
    metricTypeOptions.push({ key: metricType.id, text: startCase(metricType.name) });
  }

  let metricGroupOptions = [{ key: undefined, text: "" }];

  for (let i = 0; i < healthMetricGroups?.length; i++) {
    let metricGroup = healthMetricGroups[i];
    metricGroupOptions.push({ key: metricGroup.id, text: startCase(metricGroup.name) });
  }

  let trendIndicatorTypeOptions = [
    { key: "", text: "" },
    { key: "arrow", text: "arrow" },
    { key: "signal", text: "signal" },
    { key: "triangle", text: "triangle" },
  ];

  let trendDirectionOptions = [
    { key: "", text: "" },
    { key: "up", text: "up" },
    { key: "down", text: "down" },
    { key: "flat", text: "flat" },
  ];

  let trendShapeOptions = [
    { key: "", text: "" },
    { key: "circle", text: "circle" },
    { key: "square", text: "square" },
    { key: "triangle", text: "triangle" },
  ];

  // Add metric id to each metric definition.
  let healthMetrics = team?.healthMetrics;

  healthMetrics?.forEach((healthMetric) => {
    healthMetric["metricId"] = getMetricId(healthMetric, team);
  });

  const getCustomTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const healthMetric = dataContext?.context.healthMetrics[index],
      metricGroup = healthMetricGroups.find((metricGroup) => metricGroup.id === healthMetric?.metricGroup),
      metricGroupName = metricGroup?.name,
      metricType = healthMetricTypes.find((metricType) => metricType.id === healthMetric?.metricType),
      metricTypeName = metricType?.name,
      metricSubTypeName = metricGroup?.metricSubTypes?.find(
        (metricSubType) => metricSubType.id === healthMetric?.metricSubType
      )?.name,
      metricChildTypeName = metricType?.metricChildTypes?.find(
        (childType) => childType.id === healthMetric?.metricChildType
      )?.name;

    return `${metricGroupName}${metricTypeName ? " > " : ""}${metricTypeName || ""}${metricSubTypeName ? " > " : ""}${
      metricSubTypeName || ""
    }${metricChildTypeName ? " > " : ""}${metricChildTypeName || ""}`;
  };

  return [
    {
      fieldName: "healthMetricsSortOrder",
      fieldType: FieldType.number,
      label: "Team Sort Order",
      value: team?.healthMetricsSortOrder,
      width: "120px",
      valueWidth: "60px",
      hints: "A number to determine the sort order of the teams when displayed as health metric columns.",
    },
    {
      fieldName: "healthMetricHeaderStyle",
      fieldType: FieldType.json,
      label: "Team Header Style",
      value: team?.healthMetricHeaderStyle,
      width: "400px",
      hints: "React CSS style properties for team name as metric header.",
    },
    {
      fieldName: "healthMetrics",
      fieldType: FieldType.items,
      value: healthMetrics,
      label: "Health Metrics",
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Metric",
      collapsed: false,
      customTitle: getCustomTitle,
      customTabHeader: getCustomTitle,
      fields: [
        {
          fieldName: "metricGroup",
          fieldType: FieldType.enumeration,
          label: "Metric Group",
          options: metricGroupOptions,
          width: "100px",
          hints: "Available metric groups are defined in App Settings's HealthMetricGroups.",
        },
        {
          fieldName: "metricType",
          fieldType: FieldType.enumeration,
          label: "Metric Type",
          options: metricTypeOptions,
          width: "280px",
          hints: "Available metric types are defined in App Settings's HealthMetricTypes.",
        },
        {
          fieldName: "metricSubType",
          fieldType: FieldType.enumeration,
          label: "Metric Sub Type",
          options: (dataContext: IDataContext) => getMetricSubTypeOptions(dataContext, healthMetricGroups),
          width: "140px",
          visible: (dataContext: IDataContext) => getMetricSubTypeOptions(dataContext, healthMetricGroups)?.length > 0,
          hints:
            "Available metric sub types are defined in App Settings's HealthMetricGroups based on individual metric group.",
        },
        {
          fieldName: "metricChildType",
          fieldType: FieldType.enumeration,
          label: "Metric Child Type",
          options: (dataContext: IDataContext) => getMetricChildTypeOptions(dataContext, healthMetricTypes),
          width: "240px",
          visible: (dataContext: IDataContext) => getMetricChildTypeOptions(dataContext, healthMetricTypes)?.length > 0,
          hints:
            "Available metric child types are defined in App Settings's HealthMetricTypes based on individual metric type.",
        },
        {
          fieldName: "metricId",
          fieldType: FieldType.displayOnly,
          label: "Metric Id",
        },
        {
          fieldName: "disabled",
          fieldType: FieldType.boolean,
          label: "Disabled",
          hints: "Indicate if this metric should be disabled from being displayed.",
        },
        {
          fieldName: "emailEnabled",
          fieldType: FieldType.boolean,
          label: "Email Enabled",
          hints: "Indicate if this metric should be enabled for email alert.",
        },
        {
          fieldName: "useForCCI",
          fieldType: FieldType.boolean,
          label: "Use For CCI",
          hints: "Indicate if this metric should be used to calculate the Close Confidence Index.",
        },
        {
          fieldName: "ignoreMissingData",
          fieldType: FieldType.boolean,
          label: "Ignore Missing Data",
          hints: "Indicate if missing data logic should be ignored when trying to display the template content.",
          lastInRow: true,
        },

        ...getStatsCommonFields(testDataSource),
        {
          fieldName: "standardTemplateName",
          fieldType: FieldType.enumeration,
          label: "Standard Template",
          width: "300px",
          options: standardTemplateOptions,
        },
        {
          fieldName: "template",
          label: "Custom Template",
          lastInRow: true,
          allowFullEditor: true,
          hints:
            // eslint-disable-next-line
            "If standard template is not used, specify the custom template here for displaying the metric tile.  Placeholders are used to reference data fields returned by the query, e.g. '${this.controlPass} of ${this.controlTotal} <b>on track</b>', assuming the query returns the controlPass and controlTotal as values.",
        },
        {
          fieldName: "badgeStandardTemplateName",
          fieldType: FieldType.enumeration,
          label: "Badge Standard Template",
          width: "300px",
          options: standardTemplateOptions,
        },
        {
          fieldName: "badgeTemplate",
          label: "Badge Custom Template",
          lastInRow: true,
          allowFullEditor: true,
          hints:
            // eslint-disable-next-line
            "If badge standard template is not used, specify the badge custom template here for displaying the metric badge.  Placeholders are used to reference data fields returned by the query, e.g. '${this.controlPass}', assuming the query returns the controlPass as value.",
        },
        {
          fieldName: "tooltipStandardTemplateName",
          fieldType: FieldType.enumeration,
          label: "Tooltip Standard Template",
          width: "300px",
          options: standardTemplateOptions,
        },
        {
          fieldName: "tooltipTemplate",
          label: "Tooltip Custom Template",
          lastInRow: true,
          allowFullEditor: true,
          hints:
            // eslint-disable-next-line
            "If tooltip standard template is not used, specify the custom template here for displaying the metric tooltip.  Placeholders are used to reference data fields returned by the query, e.g. '${this.controlPass} of ${this.controlTotal} <b>on track</b>', assuming the query returns the controlPass and controlTotal as values.",
        },
        {
          fieldName: "emailStandardTemplateName",
          fieldType: FieldType.enumeration,
          label: "Email Standard Template",
          width: "300px",
          options: standardTemplateOptions,
        },
        {
          fieldName: "emailTemplate",
          label: "Email Custom Template",
          lastInRow: true,
          allowFullEditor: true,
          hints:
            // eslint-disable-next-line
            "If email standard template is not used, specify the custom template here for displaying the metric tile in email.  Placeholders are used to reference data fields returned by the query, e.g. '${this.controlPass} of ${this.controlTotal} <b>on track</b>', assuming the query returns the controlPass and controlTotal as values.",
        },
        {
          fieldName: "linkUrl",
          label: "Link Url",
          width: "100%",
          hints: "Optional field to specify the link url on click. In general, use only relative url with path only.",
        },
        {
          fieldName: "valueDisplayType",
          fieldType: FieldType.enumeration,
          label: "Badge Display Type",
          width: "200px",
          options: getDisplayTypeOptions(),
        },
        {
          fieldName: "decimal",
          fieldType: FieldType.number,
          label: "Badge Decimal",
          width: "100px",
          valueWidth: "40px",
        },
        {
          fieldName: "useWatermark",
          fieldType: FieldType.boolean,
          label: "Use Watermark",
          hints:
            "Indicate if watermark for the badge should be used. Also it only works with the 'percentage' Badge Display Type",
        },
        {
          fieldName: "showTextAsLink",
          fieldType: FieldType.boolean,
          label: "Show Text As Link",
          hints: "Show the entire template text as link.",
        },
        {
          fieldName: "statusColorOverride",
          label: "Status Color",
          width: "160px",
          hints:
            "Override status color with this fixed value. Use standard HTML color names, e.g. red, or color hex value, e.g. #FF0000.  Multiple colors can be used (comma separated list of colors).",
        },
        {
          fieldName: "statusColorDomainOverride",
          label: "Status Color Domain",
          width: "160px",
          hints:
            "Override status color domain with this fixed value if multiple colors are defined, e.g. 0,0.2,0.8 for 3 colors with first color for 0-20%, 2nd color for 20-80%, 3rd color for 80-100%.  Default to even portion among the number of colors.",
        },
        {
          fieldName: "disableTooltipIcon",
          fieldType: FieldType.boolean,
          label: "Disable Tooltip Icon",
          hints: "Force to not display the tooltip icon.",
        },
        {
          fieldType: FieldType.container,
          label: "Trending",
          collapsible: true,
          collapsed: false,
          fields: [
            {
              fieldName: "useTrend",
              fieldType: FieldType.boolean,
              label: "Use Trend Indicator",
              hints:
                "Indicate if trend indicator should be prepared for use.  Trend direction is required and trend color is optional.",
            },
            {
              fieldName: "trendIndicatorType",
              fieldType: FieldType.enumeration,
              label: "Trend Indicator Type",
              options: trendIndicatorTypeOptions,
              visible: "!!this.useTrend",
              hints: "Specify which trend indicator type to be displayed.",
            },
            {
              fieldName: "trendShapeOverride",
              fieldType: FieldType.enumeration,
              label: "Trend Shape",
              options: trendShapeOptions,
              width: "100px",
              visible: "!!this.useTrend && this.trendIndicatorType === 'signal'",
              hints: "Override trend shape value. Default to circle.",
            },
            {
              fieldName: "trendDirectionOverride",
              fieldType: FieldType.enumeration,
              label: "Trend Direction",
              options: trendDirectionOptions,
              width: "100px",
              visible: "!!this.useTrend && this.trendIndicatorType !== 'signal'",
              hints: "Override trend direction value.",
            },
            {
              fieldName: "trendColorOverride",
              label: "Trend Color",
              width: "100px",
              visible: "!!this.useTrend",
              valueWidth: "100px",
              hints:
                "Override trend color with this fixed value. Use standard HTML color names, e.g. red, or color hex value, e.g. #FF0000",
            },
            {
              fieldName: "trendIndicatorType2",
              fieldType: FieldType.enumeration,
              label: "2nd Trend Indicator Type",
              options: trendIndicatorTypeOptions,
              visible: "!!this.useTrend",
              hints: "Specify which trend indicator type to be displayed.",
            },
            {
              fieldName: "trendShapeOverride2",
              fieldType: FieldType.enumeration,
              label: "2nd Trend Shape",
              options: trendShapeOptions,
              width: "100px",
              visible: "!!this.useTrend && this.trendIndicatorType2 === 'signal'",
              hints: "Override trend shape value. Default to circle.",
            },
            {
              fieldName: "trendDirectionOverride2",
              fieldType: FieldType.enumeration,
              label: "2nd Trend Direction",
              options: trendDirectionOptions,
              width: "100px",
              visible: "!!this.useTrend && this.trendIndicatorType2 !== 'signal'",
              hints: "Override trend direction value.",
            },
            {
              fieldName: "trendColorOverride2",
              label: "2nd Trend Color",
              width: "100px",
              visible: "!!this.useTrend",
              valueWidth: "100px",
              lastInRow: true,
              hints:
                "Override trend color with this fixed value. Use standard HTML color names, e.g. red, or color hex value, e.g. #FF0000",
            },
            {
              fieldName: "showTrendChart",
              fieldType: FieldType.boolean,
              label: "Show Trend Chart",
              hints:
                "Indicate if trend chart should be displayed.  Trending data source and query are required to display the chart though.",
            },
            {
              fieldName: "trendChartPlacement",
              fieldType: FieldType.enumeration,
              label: "Chart Placement",
              options: [
                { key: "text", text: "text" },
                { key: "tooltip", text: "tooltip" },
              ],
              width: "140px",
              visible: "!!this.showTrendChart",
              hints: "Indicate where the trend chart should be displayed.",
            },
            {
              fieldName: "trendChart",
              fieldType: FieldType.context,
              collapsible: false,
              collapsed: false,
              visible: "!!this.showTrendChart",
              fields: getTileFields(),
            },
          ],
        },
      ],
    },
  ];
};

export const getFmeCloseIssuesFields = (fmeCloseIssues: IFmeCloseIssue[]): IField => ({
  fieldName: "fmeCloseIssues",
  fieldType: FieldType.items,
  value: fmeCloseIssues,
  label: "FME Close Issues",
  itemDisplayView: ItemDisplayView.tab,
  itemTypeName: "Issue",
  collapsed: false,
  fields: [
    {
      fieldName: "createdDate",
      fieldType: FieldType.date,
      label: "Created Date",
      required: true,
      width: "150px",
    },
    {
      fieldName: "resolutionDate",
      fieldType: FieldType.date,
      label: "Resolution Date",
      width: "150px",
    },
    {
      fieldName: "area",
      label: "Area",
      width: "160px",
    },
    {
      fieldName: "issueOwner",
      label: "Issue Owner",
      width: "160px",
    },
    {
      fieldName: "status",
      fieldType: FieldType.enumeration,
      label: "Status",
      options: ["Green", "Yellow", "Red"].map((s) => ({ key: s, text: s, ariaLabel: s })),
    },
    {
      fieldName: "statusNote",
      fieldType: FieldType.html,
      label: "Status Note",
      lastInRow: true,
    },
    {
      fieldName: "issue",
      fieldType: FieldType.html,
      label: "Issue",
      width: "100%",
    },
  ],
});

export const getStreamsFields = (team: ITeam, teams: ITeam[], testDataSource: Function): IField[] => {
  let teamsOptions = teams?.map((team) => ({ key: team.id, text: team.name }));

  return [
    {
      fieldName: "streamEnabled",
      fieldType: FieldType.boolean,
      label: "Stream Enabled",
      hints: "Enable this team for 'streams' display.",
    },
    {
      fieldName: "upStreams",
      fieldType: FieldType.items,
      value: team?.upStreams,
      label: "Up Streams",
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Stream",
      collapsed: false,
      fields: [
        {
          fieldName: "teamId",
          fieldType: FieldType.enumeration,
          label: "Team",
          options: teamsOptions,
          required: true,
          width: "150px",
        },
        {
          fieldName: "streamId",
          label: "Stream Id",
          width: "300px",
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Stream Schedule Data",
      collapsed: false,
      fields: [
        ...getStreamRunSettings("streamSchedule"),
        {
          fieldName: "streamScheduleDataSources",
          fieldType: FieldType.items,
          label: "Data Sources",
          itemTypeName: "Data Source",
          collapsed: false,
          compact: true,
          fields: [...getStreamSourceFields(testDataSource)],
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Stream Run Data",
      collapsed: false,
      fields: [
        ...getStreamRunSettings("streamRun"),
        {
          fieldName: "streamRunDataSources",
          fieldType: FieldType.items,
          label: "Data Sources",
          itemTypeName: "Data Source",
          collapsed: false,
          compact: true,
          fields: [...getStreamSourceFields(testDataSource)],
        },
      ],
    },
  ];
};

export const getStreamRunSettings = (fieldNamePrefix: string): IField[] => [
  {
    fieldName: `${fieldNamePrefix}RunInterval`,
    label: "Run Interval",
    valueWidth: "60px",
    regEx: "^[0-9]+[mdhn]$",
    firstInRow: true,
    hints:
      "Run frequency of the query, e.g. 5n for every 5 minutes, 1h for every hour, 1d for every day, 1m for every month",
  },
  {
    fieldName: `${fieldNamePrefix}RunStartTime`,
    label: "Start Time",
    valueWidth: "60px",
    fillWidth: false,
    regEx: "^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])$",
    visible: `this.${fieldNamePrefix}RunInterval && 'dm'.indexOf(this.${fieldNamePrefix}RunInterval[this.${fieldNamePrefix}RunInterval.length - 1]) >= 0`,
    lastInRow: true,
    hints: "Run start time of the query in PST military time, e.g. 08:00, 15:30",
  },
];

export const getStreamSourceFields = (testDataSource: Function): IField[] => [
  ...getTileDataSourceFields(),
  {
    fieldName: "query",
    label: "Query",
    width: "100%",
    allowFullEditor: true,
    hints: "The query result is expected to return dataset based on the expected schema.",
  },
  {
    fieldName: "testDataSource",
    fieldType: FieldType.button,
    label: "Test Data Source",
    firstInRow: true,
    visible: !!testDataSource,
    onClick: testDataSource,
  },
];

export const getTeamDataSourcesFields = (team: ITeam, testDataSource: Function): IField[] => [
  {
    fieldName: "teamDataSources",
    fieldType: FieldType.items,
    value: team?.teamDataSources,
    label: "Team Data Sources",
    itemTypeName: "Data Source",
    collapsed: false,
    fields: [
      ...getTileDataSourceFields(),
      {
        fieldName: "returnRowCount",
        fieldType: FieldType.boolean,
        label: "Return Row Count",
        width: "120px",
        hints: "Indicate if the result will return the 'rowCount' of the query instead.",
      },
      {
        fieldName: "rowCountFieldName",
        label: "Row Count Field Name",
        visible: `!!this.returnRowCount`,
        width: "160px",
        hints: "Optionally specific field name for the row count. By default, use 'rowCount'.",
      },
      {
        fieldName: "query",
        label: "Query",
        width: "100%",
        allowFullEditor: true,
        hints:
          "The query result is expected to return dataset of two columns: 'name' and 'value' with each row as one name-value pair.",
      },
      {
        fieldName: "runDelay",
        fieldType: FieldType.number,
        label: "Run Delay",
        width: "80px",
        valueWidth: "60px",
        hints: "Specify the number of seconds in delay before running the query.",
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
      },
    ],
  },
];
