import React, { useEffect, useReducer } from "react";
import { RouteComponentProps } from "react-router-dom";
import AppModuleHeader from "../../common/AppModuleHeader";
import { SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { getCommandBarProps, getColumns } from "./ConfigItemList.helper";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { Icon } from "@fluentui/react/lib/Icon";
import { Toggle } from "@fluentui/react/lib/Toggle";
import ConfirmModal from "../../common/ConfirmModal";
import ConfirmParametersModal from "../../common/ConfirmParametersModal";
import AlertModal from "../../common/AlertModal";
import { appInsights } from "../../../app/telemetryHelper";
import { getUserId } from "../../common/helper";
import { IState } from "../../../reducers/interfaces";
import { actionCreator as appActionCreator } from "../../../app/duck";
import { actionCreator } from "../../duck";
import { errorType } from "../../interfaces";
import classNamesBase from "./ConfigItemList.module.scss";
import { liveOnlyLinks } from "../../../app/LeftNav.helper";
import { getInvalidStagingConfigError } from "./helper";
import TileDetailsList from "../../../shared/components/Tile/TileDetailsList";

export interface IConfigItemListStateProps {
  isSmallScreen: boolean;
  isStaging: boolean;
  isSuperAdmin: boolean;
  isProduction: boolean;
  userId: string;
  loading: boolean;
  deleting: boolean;
  publishing: boolean;
  publishSuccess: boolean;
  reverting: boolean;
  revertSuccess: boolean;
  configError: string;
  configItems: any[];
}

export interface IConfigItemListDispatchProps {
  selectLeftNavLinkByUrl: (url: string) => void;
  publishReset: () => void;
  loadConfigItems?: (refresh?: boolean) => void;
  deleteConfigItem?: (configItemId: string) => void;
  publishConfigItem?: (configItemId: string, logData: object) => void;
  unpublishConfigItem?: (configItemId: string, logData: object) => void;
  revertConfigItem?: (configItemId: string) => void;
}

export interface IConfigItemListBaseProps
  extends IConfigItemListStateProps,
    IConfigItemListDispatchProps,
    RouteComponentProps {}

export interface IConfigItemListProps extends IConfigItemListBaseProps {
  leftNavUrl: string;
  configItemName: string;
  appInsightsPageName: string;
  columns: IColumn[];
  statusContent?: JSX.Element;
  getCustomActions?: (item) => JSX.Element;
  classNames?: any;
  customActionColumnWidth?: number;
}

export interface IConfigItemListState {
  showDeleteModal?: boolean;
  showRevertModal?: boolean;
  targetConfigItemId?: string;
  showAllConfigItems?: boolean;
  showPublishModal?: boolean;
  publishDevOpsUrl?: string;
  businessJustification?: string;
  publishError?: string;
  unpublish?: boolean;
  showErrorModal?: boolean;
  errorModalText?: string;
}

export const ConfigItemList = (props: IConfigItemListProps) => {
  const {
    userId,
    loading,
    configItemName,
    deleting,
    publishing,
    publishSuccess,
    reverting,
    revertSuccess,
    configError,
    statusContent,
    isStaging,
    leftNavUrl,
    classNames,
    customActionColumnWidth,
    configItems,
    columns,
    appInsightsPageName,
    getCustomActions,
    selectLeftNavLinkByUrl,
    loadConfigItems,
    publishReset,
  } = props;

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    showAllConfigItems: localStorage["showAllConfigItems"] === "true",
  });
  const {
    showAllConfigItems,
    showDeleteModal,
    showRevertModal,
    targetConfigItemId,
    showPublishModal,
    publishError,
    unpublish,
    showErrorModal,
    errorModalText,
    businessJustification,
    publishDevOpsUrl,
  } = state;

  const classNamesOverride = { ...classNamesBase, ...classNames };

  useEffect(() => {
    // Default config item listing to show "mine" when switching to a different config type.
    if (localStorage["lastConfigItemName"] !== configItemName) {
      localStorage["showAllConfigItems"] = false;
      localStorage["lastConfigItemName"] = configItemName;
    }

    selectLeftNavLinkByUrl(leftNavUrl);
    loadConfigItems();
    publishReset();

    appInsights.trackPageView({ name: appInsightsPageName });
  }, [appInsightsPageName, configItemName, leftNavUrl, selectLeftNavLinkByUrl, loadConfigItems, publishReset]);

  const myConfigItems = showAllConfigItems
    ? configItems
    : configItems?.filter(
        (configItem) =>
          (configItem.editors && configItem.editors.indexOf(userId) >= 0) ||
          (configItem.owner && configItem.owner.indexOf(userId) >= 0)
      );

  const targetConfigItem = myConfigItems?.find((item) => item.id === targetConfigItemId),
    soxCompliance = targetConfigItem && (targetConfigItem.soxCompliance || targetConfigItem.soxComplianceLive),
    publishDialogContentText = `to ${
      unpublish ? "unpublish" : "publish"
    } ${configItemName.toLowerCase()} #${targetConfigItemId} ${unpublish ? "from" : "to"} Commerce Radar Live`;

  const onTryConfigItemDelete = (configItem) => setState({ showDeleteModal: true, targetConfigItemId: configItem.id });

  const onTryConfigItemRevert = (configItem) => setState({ showRevertModal: true, targetConfigItemId: configItem.id });

  const onConfigItemDelete = () => {
    const { deleteConfigItem } = props;

    deleteConfigItem && deleteConfigItem(targetConfigItemId);
    setState({ showDeleteModal: false, targetConfigItemId: null });
  };

  const onConfigItemRevert = () => {
    const { revertConfigItem } = props;

    revertConfigItem && revertConfigItem(targetConfigItemId);
    setState({ showRevertModal: false, targetConfigItemId: null });
  };

  const onTryConfigItemPublish = (configItem) => onTryConfigItemPublishOrUnpublish(configItem, false);

  const onTryConfigItemUnpublish = (configItem) => onTryConfigItemPublishOrUnpublish(configItem, true);

  const onTryConfigItemPublishOrUnpublish = (configItem, unpublish: boolean) => {
    const { soxCompliance, soxComplianceLive, _modifiedBy, id } = configItem;
    const { userId, publishReset } = props;

    publishReset();

    if ((soxCompliance || soxComplianceLive) && _modifiedBy === userId) {
      setState({
        showErrorModal: true,
        errorModalText: `You are not allowed to publish or unpublish this item because this item is enabled for SOX Compliance and you are the same person who last modified it.  Please ask another owner/editor to publish/unpublish this item.`,
      });
    } else {
      setState({
        showPublishModal: true,
        targetConfigItemId: id,
        businessJustification: null,
        publishDevOpsUrl: null,
        unpublish,
      });
    }
  };

  const onConfigItemSoxPublishOrUnpublish = () => {
    const { publishConfigItem, unpublishConfigItem } = props;

    if (businessJustification) {
      let logData = { businessJustification, publishDevOpsUrl };

      unpublish ? unpublishConfigItem(targetConfigItemId, logData) : publishConfigItem(targetConfigItemId, logData);
      setState({ showPublishModal: false, publishError: null });
    } else {
      setState({ publishError: `Business Justification is required to ${unpublish ? "unpublish" : "publish"}.` });
    }
  };

  const onConfigItemPublishOrUnpublish = () => {
    const { publishConfigItem, unpublishConfigItem } = props;
    const logData = {};

    unpublish ? unpublishConfigItem(targetConfigItemId, logData) : publishConfigItem(targetConfigItemId, logData);
    setState({ showPublishModal: false, publishError: null });
  };

  const finalColumns = getColumns(
    columns,
    props,
    classNamesOverride,
    onTryConfigItemDelete,
    onTryConfigItemPublish,
    onTryConfigItemUnpublish,
    onTryConfigItemRevert,
    getCustomActions,
    customActionColumnWidth
  );

  if (!userId) return null;

  if (isStaging && liveOnlyLinks.indexOf(leftNavUrl) >= 0) {
    return getInvalidStagingConfigError(classNamesOverride, configItemName);
  }

  if (loading) {
    return (
      <Spinner
        styles={{
          root: classNamesOverride.spinner,
          circle: classNamesOverride.spinnerCircle,
          label: classNamesOverride.spinnerLabel,
        }}
        label={`Loading ${configItemName.toLowerCase()} data...`}
      />
    );
  }

  return (
    <div className={classNamesOverride.appModuleContent}>
      <AppModuleHeader commandBarProps={getCommandBarProps(props)} />
      {statusContent}
      {deleting && (
        <div className={classNamesOverride.spinnerPane}>
          <Spinner
            styles={{
              root: classNamesOverride.spinner,
              circle: classNamesOverride.spinnerCircle,
              label: classNamesOverride.spinnerLabel,
            }}
            label="Deleting item..."
          />
        </div>
      )}
      {(publishing || reverting) && (
        <div className={classNamesOverride.spinnerPane}>
          <Spinner
            styles={{
              root: classNamesOverride.spinner,
              circle: classNamesOverride.spinnerCircle,
              label: classNamesOverride.spinnerLabel,
            }}
            label={`${reverting ? "Reverting" : unpublish ? "Unpublishing" : "Publishing"} item...`}
          />
        </div>
      )}
      {publishSuccess && (
        <div className={classNamesOverride.successMessage}>
          <Icon iconName="SkypeCircleCheck" className={classNamesOverride.successIcon} /> The selected item with ID #
          {targetConfigItemId} is {unpublish && "un"}published {unpublish ? "from" : "to"} Live successfully.
        </div>
      )}
      {revertSuccess && (
        <div className={classNamesOverride.successMessage}>
          <Icon iconName="SkypeCircleCheck" className={classNamesOverride.successIcon} /> The selected item is reverted
          from Live to Staging successfully.
        </div>
      )}
      {configError && <div className={classNamesOverride.error}>{configError}</div>}
      <Toggle
        onText="Mine"
        offText="All"
        defaultChecked={!showAllConfigItems}
        ariaLabel={"Showing only my configs"}
        className={classNamesOverride.showAllToggle}
        onChange={() => {
          localStorage["showAllConfigItems"] = !showAllConfigItems;
          setState({ showAllConfigItems: !showAllConfigItems });
        }}
      />
      <TileDetailsList
        title={`My ${configItemName} List`}
        nameForSave={configItemName}
        style={{ minHeight: "8em" }}
        showSearchBox
        items={myConfigItems}
        columns={finalColumns}
        selectionMode={SelectionMode.none}
        classNameForDetailsList={classNamesOverride.editableItems}
        noItemText={`No ${configItemName.toLowerCase()} is found.`}
      />

      {showDeleteModal && (
        <ConfirmModal
          content={`Are you sure you want to delete ${configItemName.toLowerCase()} #${targetConfigItemId}?`}
          onCommit={onConfigItemDelete}
          onCancel={() => setState({ showDeleteModal: false, targetConfigItemId: null })}
        />
      )}
      {showRevertModal && (
        <ConfirmModal
          content={`Are you sure you want to revert the config of this ${configItemName.toLowerCase()} from Live to Staging?`}
          onCommit={onConfigItemRevert}
          onCancel={() => setState({ showRevertModal: false, targetConfigItemId: null })}
        />
      )}
      {showErrorModal && errorModalText && (
        <AlertModal
          content={errorModalText}
          onCommit={() => setState({ showErrorModal: false, errorModalText: null })}
        />
      )}

      {showPublishModal &&
        (soxCompliance ? (
          <ConfirmParametersModal
            content={
              <div>
                Please enter the business justification and the optional DevOps URL {publishDialogContentText}.<br />
                This is required for SOX compliance. Please explain clearly why this change is needed.
              </div>
            }
            parameters={[
              {
                prompt: "Business Justification",
                name: "businessJustification",
                rows: 5,
              },
              { prompt: "DevOps URL", name: "publishDevOpsUrl", optional: true },
            ]}
            modalRootClassName={classNamesBase.soxConfirmModal}
            onParameterChange={(parameterValues) => setState({ ...parameterValues, publishError: null })}
            error={publishError}
            onCommit={onConfigItemSoxPublishOrUnpublish}
            onCancel={() => setState({ showPublishModal: false, targetConfigItemId: null, publishError: null })}
          />
        ) : (
          <ConfirmModal
            content={`Are you sure you want ${publishDialogContentText}?`}
            onCommit={onConfigItemPublishOrUnpublish}
            onCancel={() => setState({ showPublishModal: false, targetConfigItemId: null, publishError: null })}
          />
        ))}
    </div>
  );
};

export default ConfigItemList;

export const mapStateToProps = (state: IState): IConfigItemListStateProps => ({
  isSmallScreen: state.app.is_small_screen,
  isStaging: state.app.is_staging,
  isProduction: state.app.is_production,
  userId: getUserId(state.app.login_user),
  isSuperAdmin: state.app.login_user_info && state.app.login_user_info.isSuperAdmin,
  configItems: state.modules.config_items,
  loading: state.modules.loading_config_items,
  deleting: state.modules.saving_config,
  publishing: state.modules.publishing_config,
  publishSuccess: state.modules.publishing_config_success,
  reverting: state.modules.reverting_config,
  revertSuccess: state.modules.reverting_config_success,
  configError: state.modules.errors[errorType.config],
});

export const mapDispatchToProps: IConfigItemListDispatchProps = {
  selectLeftNavLinkByUrl: appActionCreator.selectLeftNavLinkByUrl,
  publishReset: actionCreator.publishConfigItemReset,
};
