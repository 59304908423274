import { IAppSetting, ITeam } from "../../common/interfaces";
import { IField, FieldType } from "../../../shared/components/DataForm";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { IHealthMetricSet } from "../../home/HealthDashboard.types";

export const defaultAppSetting: IAppSetting = {
  id: "",
};

export const getFields = (appSetting: IAppSetting, teams: ITeam[], healthMetricSets: IHealthMetricSet[]): IField[] => {
  if (!appSetting) return null;

  const teamsOptions = teams?.map((team) => ({ key: team.id, text: team.name }));

  let metricSetOptions = [{ key: undefined, text: "" }];

  for (let i = 0; i < healthMetricSets?.length; i++) {
    let metricSet = healthMetricSets[i];
    metricSetOptions.push({ key: metricSet.id, text: metricSet.name });
  }

  return [
    {
      fieldName: "id",
      label: "App Setting ID",
      value: appSetting.id,
      required: true,
      width: "400px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: appSetting.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      fieldType: FieldType.textArray,
      label: "Editors",
      value: appSetting.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: appSetting.description,
      width: "100%",
    },
    {
      fieldName: "settings",
      fieldType: FieldType.json,
      label: "Settings",
      value: appSetting.settings,
      width: "100%",
      visible:
        "this.id !== 'IcM:TeamNameMapping' && this.id !== 'IcM:EngineeringDirectorMapping' && this.id !== 'IcMConnectors' && this.id !== 'StandardTemplates' && this.id !== 'HealthMetricTypes' && this.id !== 'HealthMetricGroups' && this.id !== 'HealthMetricSets' && this.id !== 'FeatureFlags' && this.id !== 'ControlMetadata'",
    },
    {
      fieldName: "mappings",
      label: "Mappings",
      fieldType: FieldType.items,
      value: appSetting.mappings,
      itemTypeName: "Mapping",
      itemDisplayView: ItemDisplayView.list,
      collapsed: false,
      visible: "this.id === 'IcM:TeamNameMapping' || this.id === 'IcM:EngineeringDirectorMapping'",
      fields: [
        {
          fieldName: "source",
          label: "Source Name/Pattern",
          width: "50%",
          required: true,
          hints:
            "The source name to be mapped. Regular expression pattern can be used here, e.g. XYZ.* will match any name starting with XYZ.",
        },
        {
          fieldName: "target",
          label: "Target Name",
          required: true,
          hints: "The target name to be mapped to.",
        },
      ],
    },
    {
      fieldName: "settings",
      label: "Supported IcM Connectors",
      fieldType: FieldType.items,
      value: appSetting.settings,
      itemTypeName: "Connector",
      itemDisplayView: ItemDisplayView.list,
      collapsed: false,
      visible: "this.id === 'IcMConnectors'",
      fields: [
        {
          fieldName: "id",
          label: "IcM Connector ID",
          width: "300px",
          hints: "ID for IcM Connector that has been onboarded to support the CommerceRadar IcM Connector",
        },
        {
          fieldName: "serviceName",
          label: "IcM Service Name",
          width: "200px",
          hints: "Name of the IcM Service that the connector ID is defined",
        },
      ],
    },
    {
      fieldName: "templates",
      label: "Templates",
      fieldType: FieldType.items,
      value: appSetting.templates,
      itemTypeName: "Template",
      itemDisplayView: ItemDisplayView.list,
      collapsed: false,
      visible: "this.id === 'StandardTemplates'",
      fields: [
        {
          fieldName: "name",
          label: "Template Name",
          width: "300px",
          hints: "A simple name for identifying the template.",
        },
        {
          fieldName: "value",
          label: "Template Value",
          width: "100%",
          allowFullEditor: true,
          hints: "Actual value of the template.",
        },
      ],
    },
    {
      fieldName: "settings",
      label: "Metric Types",
      fieldType: FieldType.items,
      value: appSetting.settings,
      itemTypeName: "Type",
      itemDisplayView: ItemDisplayView.list,
      collapsed: false,
      visible: "this.id === 'HealthMetricTypes'",
      fields: [
        {
          fieldName: "id",
          fieldType: FieldType.number,
          label: "ID",
          width: "60px",
          hints: "A unique integer to identify this metric type",
        },
        {
          fieldName: "name",
          label: "Type Name",
          width: "200px",
          hints: "Display name of this metric type",
        },
        {
          fieldName: "description",
          label: "Description",
          lastInRow: true,
          hints: "Description of this metric type. HTML is allowed.",
        },
        {
          fieldName: "metricSet",
          fieldType: FieldType.enumeration,
          label: "Metric Set",
          options: metricSetOptions,
          width: "140px",
          hints: "Available metric sets are defined in App Settings's HealthMetricSets.",
        },
        {
          fieldName: "metricChildTypes",
          label: "Metric Child Types",
          fieldType: FieldType.items,
          itemTypeName: "Child Type",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          hints:
            "Define list of metric child types for the metric type, e.g. some 'Executive' metric types require multiple child types.",
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.number,
              label: "ID",
              width: "60px",
              hints: "A unique integer to identify this metric child type",
            },
            {
              fieldName: "name",
              label: "Type Name",
              width: "200px",
              hints: "Display name of this metric child type",
            },
            {
              fieldName: "description",
              label: "Description",
              lastInRow: true,
              hints: "Description of this metric child type. HTML is allowed.",
            },
          ],
        },
      ],
    },
    {
      fieldName: "settings",
      label: "Metric Groups",
      fieldType: FieldType.items,
      value: appSetting.settings,
      itemTypeName: "Metric Group",
      itemDisplayView: ItemDisplayView.tab,
      collapsed: false,
      visible: "this.id === 'HealthMetricGroups'",
      fields: [
        {
          fieldName: "id",
          fieldType: FieldType.number,
          label: "ID",
          width: "60px",
          hints: "A unique integer to identify this metric group",
        },
        {
          fieldName: "name",
          label: "Metric Group Name",
          width: "200px",
          hints: "Display name of this metric group",
        },
        {
          fieldName: "description",
          label: "Description",
          lastInRow: true,
          hints: "Description of this metric group. HTML is allowed.",
        },
        {
          fieldName: "reportId",
          fieldType: FieldType.number,
          label: "Report ID",
          width: "80px",
          hints: "The report ID related to this metric group",
        },
        {
          fieldName: "priorMonthContinuationDays",
          fieldType: FieldType.number,
          label: "Prior Month Continuation Days",
          width: "200px",
          valueWidth: "80px",
          hints: "# of calendar days continues to process for previous month data.",
        },
        {
          fieldName: "metricTypeHeaderStyle",
          fieldType: FieldType.json,
          label: "Metric Type Header Style",
          width: "400px",
          allowFullEditor: true,
          hints:
            "Specific the CSS style for applying to the metric type header of the health metric tables (i.e. the first column of the table).",
        },
        {
          fieldName: "pageNote",
          fieldType: FieldType.html,
          label: "Page Note",
          width: "400px",
          allowFullEditor: true,
          hints: "Specific the page note for this metric group.  HTML tags are allowed.",
        },
        {
          fieldName: "useMonthFilter",
          fieldType: FieldType.boolean,
          label: "Use Month Filter",
          hints: "Apply month filter to this metric group.",
        },
        {
          fieldName: "independentPage",
          fieldType: FieldType.boolean,
          label: "Independent Page",
          hints: "Display this metric group as independent page.  Don't mix with other metric groups.",
        },
        {
          fieldName: "restrictedAccess",
          fieldType: FieldType.boolean,
          label: "Restricted Access",
          hints: "Restrict access to this metric group to those with MS Sales full/WW access.",
        },
        {
          fieldName: "subscriptionUrl",
          fieldType: FieldType.html,
          label: "Subscription URL",
          width: "400px",
          hints:
            "URL to page where user can subscribe to notification for this metric group, e.g. link to join the specific SG.",
        },
        {
          fieldName: "subscriptionNoteForEmail",
          fieldType: FieldType.html,
          label: "Subscription Note For Email",
          width: "400px",
          hints:
            "Content/note to instruct user how to subscribe to notification for this metric group in Email, e.g. includes link to join the specific SG.",
        },
        {
          fieldName: "pageHeaderText",
          label: "Page Header Text",
        },
        {
          fieldName: "teams",
          label: "Teams",
          fieldType: FieldType.items,
          itemTypeName: "Team",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          hints: "Allow override for team settings specific for this metric group.",
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.enumeration,
              label: "Team",
              options: teamsOptions,
              width: "160px",
              hints: "Select a team for the following team specific settings for this metric group",
            },
            {
              fieldName: "healthMetricHeaderStyle",
              fieldType: FieldType.json,
              label: "Health Metric Header Style",
              width: "400px",
              allowFullEditor: true,
              hints: "Specific the CSS style for applying to the health metric header of this team.",
            },
            {
              fieldName: "teamTitle",
              label: "Team Title",
              valueWidth: "240px",
              hints: "Team title for this metric group.",
              lastInRow: true,
            },
            {
              fieldName: "teamNote",
              fieldType: FieldType.html,
              label: "Team Note",
              width: "400px",
              allowFullEditor: true,
              hints: "Specific the team note for this metric group.  HTML tags are allowed.",
            },
            {
              fieldName: "teamNoteForEmail",
              fieldType: FieldType.html,
              label: "Team Note for Email",
              width: "400px",
              allowFullEditor: true,
              hints: "Specific the team note for this metric group in email scenario.  HTML tags are allowed.",
            },
            {
              fieldName: "links",
              label: "Team Links",
              fieldType: FieldType.items,
              itemTypeName: "Link",
              itemDisplayView: ItemDisplayView.tab,
              collapsed: false,
              compact: true,
              fields: [
                {
                  fieldName: "name",
                  label: "Display Name",
                  width: "200px",
                  hints: "Display name of the team link",
                },
                {
                  fieldName: "url",
                  label: "Link URL",
                  lastInRow: true,
                  hints: "URL of the team link",
                },
              ],
            },
          ],
        },
        {
          fieldName: "metricSubTypes",
          label: "Metric Group Sub Types",
          fieldType: FieldType.items,
          itemTypeName: "Sub Type",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          hints:
            "Define list of metric sub types for the metric group, e.g. 'Close' requires multiple sub types for the same team.",
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.number,
              label: "ID",
              width: "60px",
              hints: "A unique integer to identify this metric sub type",
            },
            {
              fieldName: "name",
              label: "Type Name",
              width: "200px",
              hints: "Display name of this metric sub type",
            },
            {
              fieldName: "emailName",
              label: "Type Name for Email",
              width: "200px",
              hints: "Display name of this metric sub type for email. Default to Type Name.",
            },
            {
              fieldName: "description",
              label: "Description",
              lastInRow: true,
              hints: "Description of this metric sub type. HTML is allowed.",
            },
            {
              fieldName: "healthMetricHeaderStyle",
              fieldType: FieldType.json,
              label: "Health Metric Header Style",
              allowFullEditor: true,
              lastInRow: true,
              hints:
                "CSS style settings for customizing this metric table column, e.g. {'width': 200} for a fixed column width.",
            },
            {
              fieldName: "disabled",
              fieldType: FieldType.boolean,
              label: "Disabled",
              hints: "Indicate if this metric sub type should be disabled for UX display.",
            },
            {
              fieldName: "emailEnabled",
              fieldType: FieldType.boolean,
              label: "Email Enabled",
              hints: "Indicate if this metric sub type should be enabled for email alert.",
            },
            {
              fieldName: "hideInExcelExport",
              fieldType: FieldType.boolean,
              label: "Hide In Excel Export",
              hints: "Indicate if this metric sub type should be hidden for Excel export.",
              lastInRow: true,
            },
            {
              fieldName: "teams",
              label: "Teams",
              fieldType: FieldType.items,
              itemTypeName: "Team",
              itemDisplayView: ItemDisplayView.tab,
              collapsed: false,
              hints: "Allow override for team settings specific for this metric sub type.",
              fields: [
                {
                  fieldName: "id",
                  fieldType: FieldType.enumeration,
                  label: "Team",
                  options: teamsOptions,
                  width: "160px",
                  hints: "Select a team for the following team specific settings for this metric sub type.",
                },
                {
                  fieldName: "metricSet",
                  fieldType: FieldType.enumeration,
                  label: "Metric Set",
                  options: metricSetOptions,
                  width: "140px",
                  hints: "Available metric sets are defined in App Settings's HealthMetricSets.",
                },
              ],
            },
          ],
        },
        {
          fieldName: "links",
          label: "Metric Group Links",
          fieldType: FieldType.items,
          itemTypeName: "Link",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          hints: "Define list of page links for this metric groups.",
          fields: [
            {
              fieldName: "name",
              label: "Display Name",
              width: "200px",
              hints: "Display name of the team link",
            },
            {
              fieldName: "url",
              label: "Link URL",
              lastInRow: true,
              hints: "URL of the team link",
            },
          ],
        },
        {
          fieldName: "metricTypeColumns",
          fieldType: FieldType.items,
          label: "Metric Type Columns",
          hints:
            "Define list of columns for displaying metric type names. By default, one column is used for displaying the metric type name.  But some scenarios require metric type names be broken down into multiple columns with some form of hierachy.",
          itemTypeName: "Column",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.number,
              label: "Column ID",
              valueWidth: "60px",
            },
            {
              fieldName: "name",
              label: "Column Name",
            },
          ],
        },
      ],
    },
    {
      fieldName: "settings",
      label: "Metric Sets",
      fieldType: FieldType.items,
      value: appSetting.settings,
      itemTypeName: "Metric Set",
      itemDisplayView: ItemDisplayView.tab,
      collapsed: false,
      visible: "this.id === 'HealthMetricSets'",
      fields: [
        {
          fieldName: "id",
          fieldType: FieldType.number,
          label: "ID",
          width: "60px",
          hints: "A unique integer to identify this metric set",
        },
        {
          fieldName: "name",
          label: "Metric Set Name",
          width: "200px",
          hints: "Display name of this metric set",
        },
        {
          fieldName: "disabled",
          fieldType: FieldType.boolean,
          label: "Disabled",
          hints: "Indicate if this metric set should be disabled for UX display.",
        },
        {
          fieldName: "teams",
          label: "Teams",
          fieldType: FieldType.items,
          itemTypeName: "Team",
          itemDisplayView: ItemDisplayView.tab,
          collapsed: false,
          hints: "Allow override for team settings specific for this metric set.",
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.enumeration,
              label: "Team",
              options: teamsOptions,
              width: "160px",
              hints: "Select a team for the following team specific settings for this metric",
            },
            {
              fieldName: "metricSetName",
              label: "Team Metric Set Name",
              valueWidth: "240px",
              hints: "Custom metric set name for this metric set of this team.",
            },
            {
              fieldName: "doNotShowMetricSetName",
              fieldType: FieldType.boolean,
              label: "Do Not Show Metric Set Name",
            },
            {
              fieldName: "disabled",
              fieldType: FieldType.boolean,
              label: "Disabled",
              hints: "Indicate if the metric set of this team should be disabled for UX display.",
            },
          ],
        },
      ],
    },
    {
      fieldName: "disableHealthDashboard",
      fieldType: FieldType.boolean,
      label: "Disable Health Dashboard",
      visible: "this.id === 'FeatureFlags'",
    },
    {
      fieldName: "disableCopilot",
      fieldType: FieldType.boolean,
      label: "Disable Copilot",
      visible: "this.id === 'FeatureFlags'",
    },
    {
      fieldName: "disableReprocessFinancialEntities",
      fieldType: FieldType.boolean,
      label: "Disable reprocessing financial entities",
      visible: "this.id === 'FeatureFlags'",
    },
    {
      fieldName: "disableServiceBusMessageExplorer",
      fieldType: FieldType.boolean,
      label: "Disable service bus message explorer",
      visible: "this.id === 'FeatureFlags'",
    },
    {
      fieldName: "settings",
      fieldType: FieldType.context,
      value: appSetting.settings,
      visible: "this.id === 'ControlMetadata'",
      fields: [
        {
          fieldName: "financialKpiTypes",
          fieldType: FieldType.items,
          label: "Financial KPI Types",
          itemTypeName: "Type",
          collapsed: false,
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.number,
              label: "ID",
              width: "60px",
              hints: "A unique integer to identify this financial KPI type",
            },
            {
              fieldName: "name",
              label: "Name",
              width: "200px",
              hints: "Display name of this financial KPI type",
            },
            {
              fieldName: "subTypes",
              fieldType: FieldType.items,
              label: "Sub Types",
              itemTypeName: "Sub Type",
              collapsed: false,
              fields: [
                {
                  fieldName: "id",
                  fieldType: FieldType.number,
                  label: "ID",
                  width: "60px",
                  hints: "A unique integer to identify this financial KPI sub type",
                },
                {
                  fieldName: "name",
                  label: "Name",
                  width: "200px",
                  hints: "Display name of this financial KPI sub type",
                },
              ],
            },
          ],
        },
        {
          fieldName: "controlCategories",
          fieldType: FieldType.items,
          label: "Control Categories",
          itemTypeName: "Category",
          collapsed: false,
          fields: [
            {
              fieldName: "id",
              fieldType: FieldType.number,
              label: "ID",
              width: "60px",
              hints: "A unique integer to identify this control category",
            },
            {
              fieldName: "name",
              label: "Name",
              width: "200px",
              hints: "Display name of this control category",
            },
            {
              fieldName: "longName",
              label: "Long Name",
              width: "600px",
              hints: "Display long name of this control category",
            },
          ],
        },
      ],
    },
  ];
};
