import React from "react";
import { ContentDisplayType } from "../../../shared/utilities/miscHelper";
import { VisualType } from "../../../shared/components/Visual";
import { FieldType, IDataContext, IField, IFieldStatus } from "../../../shared/components/DataForm";
import { TileType } from "../../../shared/components/Tile";
import { IDataSource, ValueChevronType } from "../../common/interfaces";
import { LineMarkerType, LineType, INode } from "../../../shared/components/Diagram";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { IDropdownOption } from "@fluentui/react";
import { RollingIntervalOptions } from "../../common/serviceContentInterfaces";

export const getDisplayTypeOptions = () => {
  let displayTypes = [{ key: "", text: "" }];

  for (var displayType in ContentDisplayType) {
    displayTypes.push({ key: displayType, text: displayType });
  }

  return displayTypes;
};

export const getVisualTypeOptions = () => {
  let visualTypes = [{ key: "", text: "" }];

  for (var visualType in VisualType) {
    visualTypes.push({ key: visualType, text: visualType });
  }

  return visualTypes;
};

export const getTileTypeOptions = () => {
  let tileTypes = [{ key: "", text: "" }];

  for (var tileType in TileType) {
    tileTypes.push({ key: tileType, text: tileType });
  }

  return tileTypes;
};

export const getSeriesSortOrderOptions = () => [
  { key: "", text: "" },
  { key: "asc", text: "Ascending" },
  { key: "desc", text: "Descending" },
];

export const getLegendPositionOptions = () => [
  { key: "", text: "" },
  { key: "top", text: "top" },
  { key: "bottom", text: "bottom" },
  { key: "left", text: "left" },
  { key: "right", text: "right" },
];

export const getLineMarkerTypeOptions = () => {
  let lineMarkerTypes = [{ key: "", text: "" }];

  for (var lineMarkerType in LineMarkerType) {
    if (isNaN(Number(lineMarkerType))) {
      lineMarkerTypes.push({ key: LineMarkerType[lineMarkerType], text: lineMarkerType });
    }
  }

  return lineMarkerTypes;
};

export const getLineTypeOptions = () => {
  let lineTypes = [{ key: "", text: "" }];

  for (var lineType in LineType) {
    if (isNaN(Number(lineType))) {
      lineTypes.push({ key: LineType[lineType], text: lineType });
    }
  }

  return lineTypes;
};

export const getValueChevronTypeOptions = () => {
  let chevronTypes = [{ key: "", text: "", ariaLabel: "" }];

  for (var chevronType in ValueChevronType) {
    chevronTypes.push({ key: chevronType, text: chevronType, ariaLabel: chevronType });
  }

  return chevronTypes;
};

export const getValueCompareTypeOptions = () => {
  return [
    { key: "", text: "", ariaLabel: "" },
    { key: ">=", text: ">= target is good", ariaLabel: ">= target is good" },
    { key: "<=", text: "<= target is good", ariaLabel: "<= target is good" },
  ];
};

const isVisualTile = "!this.tileType || this.tileType === 'Visual'";
const isInputField = `(this.visualType === 'timeAgoSelector' || this.visualType === 'textbox' || this.visualType === 'comboBox' || this.visualType === 'dropdown' || this.visualType === 'datePicker' || this.visualType === 'label' || this.visualType === 'submit') && (${isVisualTile})`;
const isNotInputField = `!(${isInputField})`;
const isQueryableField = `(${isNotInputField} || this.visualType === 'comboBox' || this.visualType === 'dropdown' || this.visualType === 'label') && (${isVisualTile})`;
const showQueryField = `(${isQueryableField}) && this.dataSourceType !== 'tileDataSource'`;
const isSeriesChartField = `(${isNotInputField}) && (${isVisualTile})  && this.visualType !== 'diagram' || this.visualType === 'label'`;

export const getTilesFields = (tiles?, customTilesSettings?): IField => {
  const getName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const tile = dataContext?.context?.tiles?.length > index && dataContext.context.tiles[index];
    return tile.name;
  };
  return {
    fieldName: "tiles",
    label: "Custom Tiles",
    fieldType: FieldType.items,
    value: tiles,
    hints: "Create data visualization such as charts, data table, etc.",
    itemTypeName: "Tile",
    customTabHeader: getName,
    customTitle: getName,
    ...customTilesSettings,
    fields: getTileFields(tiles),
  };
};

export const getTileFields = (tiles?): IField[] => [
  {
    fieldName: "visualType",
    fieldType: FieldType.enumeration,
    label: "Visual Type",
    options: getVisualTypeOptions(),
    width: "180px",
    visible: isVisualTile,
  },
  {
    fieldName: "name",
    label: "Tile Name",
    width: "300px",
    visible: isNotInputField,
  },
  {
    fieldName: "categoryFieldName",
    label: "Category Field Name",
    width: "200px",
    hints: "By default, 'category' is used as the category field name.",
    visible: isSeriesChartField,
  },
  {
    fieldName: "valueFieldNames",
    fieldType: FieldType.textArray,
    label: "Value Field Names",
    width: "300px",
    hints: "Comma separated list of value field names. By default, 'value' is used as the value field name.",
    visible: isSeriesChartField,
  },
  {
    fieldName: "groupFieldNames",
    label: "Group Field Names",
    fieldType: FieldType.textArray,
    width: "300px",
    hints:
      "Comma separated list of group field names, used for grouping data into multiple series. By default, 'group' is used as the group field name.",
    visible: isSeriesChartField,
  },
  {
    fieldName: "seriesSortOrder",
    label: "Series Sort Order",
    fieldType: FieldType.enumeration,
    width: "120px",
    options: getSeriesSortOrderOptions(),
    hints:
      "Sort order by series names for multiple series dataset. By default, the sort order is based on the order series names first appearing in the query result.",
    visible: isSeriesChartField,
  },
  {
    fieldName: "filterName",
    label: "Filter Name",
    hints: "Parameter name used when selected as filter",
    visible: isInputField + " && this.visualType !== 'submit'",
  },
  {
    fieldName: "label",
    label: "Label",
    width: "300px",
    allowFullEditor: true,
    visible: isInputField,
  },
  {
    fieldName: "query",
    label: "Query",
    width: "100%",
    lastInRow: true,
    allowFullEditor: true,
    visible: showQueryField,
  },
  {
    fieldName: "visualProps",
    fieldType: FieldType.context,
    visible: "this.visualType === 'diagram'",
    width: "100%",
    fields: [
      {
        fieldName: "idFieldName",
        label: "ID Field Name",
        hints: "The column/field name that contains the entity/node id for the diagram. By default, 'id' is used.",
      },
      {
        fieldName: "metricNameFieldName",
        label: "Metric Name Field Name",
        hints:
          "The column/field name that contains the metric name for the entity/node. By default, 'metricName' is used.",
      },
      {
        fieldName: "metricValueFieldName",
        label: "Metric Value Field Name",
        hints:
          "The column/field name that contains the metric value for the entity/node. By default, 'metricValue' is used.",
      },
      {
        fieldName: "diagram",
        label: "Diagram Definition",
        fieldType: FieldType.json,
        width: "95%",
        lastInRow: true,
        hints: "Define the JSON definition of the diagram with nodes and links.",
      },
    ],
  },
  {
    label: "Advanced",
    fieldType: FieldType.container,
    collapsible: true,
    compact: true,
    visible: isInputField,
    fields: [
      {
        fieldName: "lastInRow",
        fieldType: FieldType.boolean,
        label: "Last In Row",
        hints: "As last field in a row, a row will start for subsequent fields.",
      },
      {
        fieldName: "ignoreSubmitButton",
        fieldType: FieldType.boolean,
        label: "Ignore Submit Button",
        hints: "Ignore the submit button for this tile even if 'Use Submit Button' is set for the report.",
      },
      {
        fieldName: "placeholder",
        label: "Placeholder Text",
        hints: "Hints text for the input field.",
        visible:
          "this.visualType === 'comboBox' || this.visualType === 'datePicker' || this.visualType === 'dropdown' || this.visualType === 'textbox'",
      },
      {
        fieldName: "defaultDateDiff",
        fieldType: FieldType.number,
        label: "Default Date Diff",
        width: "100px",
        hints: "Enter 0 if you want the date picker to default to today, -1 if default to yesterday, etc.",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "maxDateString",
        fieldType: FieldType.date,
        label: "Max Date",
        width: "100px",
        hints: "Select a maximum date for the date picker",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "maxDateDiff",
        fieldType: FieldType.number,
        label: "Max Date Diff",
        width: "100px",
        hints:
          "Enter 0 if you want the date picker to set maximum date at today, -1 if default to yesterday, etc.  Note: This field will be ignored if Max Date field is set.",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "minDateString",
        fieldType: FieldType.date,
        label: "Min Date",
        width: "100px",
        hints: "Select a minimum date for the date picker",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "minDateDiff",
        fieldType: FieldType.number,
        label: "Min Date Diff",
        width: "100px",
        hints:
          "Enter 0 if you want the date picker to set minmum date at today, -1 if default to yesterday, etc.  Note: This field will be ignored if Min Date field is set.",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "valueFormat",
        label: "Value Format",
        width: "100px",
        hints:
          "Specify a valid javascript date format pattern string based on Moment.js.  See https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/ for more info. By default, the ISO date format of 'YYYY-MM-DD' is used",
        visible: "this.visualType === 'datePicker'",
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.context,
        visible: "this.visualType === 'timeAgoSelector'",
        fields: [
          {
            fieldName: "selectedValue",
            label: "Selected Value",
          },
          {
            fieldName: "availableValues",
            label: "Available Values",
            fieldType: FieldType.textArray,
            lastInRow: true,
          },
        ],
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.context,
        visible: "this.visualType === 'textbox'",
        fields: [
          {
            fieldName: "value",
            label: "Default Value",
          },
          {
            fieldName: "width",
            label: "Width",
            fieldType: FieldType.number,
            width: "60px",
          },
        ],
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.context,
        visible: "this.visualType === 'comboBox'",
        fields: [
          {
            fieldName: "selectedValue",
            label: "Selected Value",
          },
          {
            fieldName: "width",
            label: "Width",
            fieldType: FieldType.number,
            width: "60px",
          },
        ],
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.context,
        visible: "this.visualType === 'dropdown'",
        lastInRow: true,
        fields: [
          {
            fieldName: "multiSelect",
            fieldType: FieldType.boolean,
            label: "Multi-Selections",
            hints: "Check to allow multiple selections of dropdown items",
          },
          {
            fieldName: "doNotInsertEmptyItem",
            fieldType: FieldType.boolean,
            label: "Do not insert empty item",
            hints:
              "By default, any empty item is inserted to dropdown for non-selection. Check to disable this default behavior.",
          },
          {
            fieldName: "doNotDefaultSelectedValue",
            fieldType: FieldType.boolean,
            label: "Do not default selected value",
            hints:
              "By default, default the selected value to the first dropdown item. Check to disable this default behavior.",
          },
          {
            fieldName: "selectedValue",
            label: "Selected Value",
          },
          {
            fieldName: "width",
            label: "Width",
            fieldType: FieldType.number,
            width: "60px",
          },
          {
            fieldName: "options",
            label: "Dropdown Options",
            fieldType: FieldType.json,
            lastInRow: true,
            width: "60%",
            hints:
              "This is the static options.  If defined, it overrides data returned from query. Example: [{'key': 'k1', 'text': 'item 1'}, {'key': 'k2', 'text': 'item 2'}]",
          },
        ],
      },
      {
        fieldName: "labelStyle",
        label: "Label Style",
        fieldType: FieldType.json,
        width: "100%",
        visible: "this.visualType === 'label'",
        hints: "Specify CSS styles for the label text",
      },
      {
        fieldName: "valueStyle",
        label: "Value Text Style",
        fieldType: FieldType.json,
        width: "100%",
        visible: "this.visualType === 'label'",
        hints: "Specify CSS styles for the value text",
      },
      {
        fieldName: "style",
        fieldType: FieldType.json,
        label: "Tile Style",
        width: "100%",
        lastInRow: true,
        hints: "Specify CSS styles for the tile",
      },
      {
        fieldName: "visibilityConditions",
        fieldType: FieldType.json,
        width: "50%",
        label: "Visibility Conditions",
        hints:
          'Specify an array of name/value pairs on filter name and value to indicate if the tile should be visible or not. E.g. if you have a filter name "someType", you can use set [{"name": "someType", "value": ["myFilterValue1", "myFilterValue2"]}] to show the tile if the value of someType equals to myFilterValue1 or myFilterValue2.',
      },
    ].concat(getTileDataSourceFields({}, isQueryableField, tiles)),
  },
  {
    label: "Advanced",
    fieldType: FieldType.container,
    collapsible: true,
    compact: true,
    visible: `(${isNotInputField}) && this.visualType !== 'actionButton'`,
    fields: [
      {
        fieldName: "subtitle",
        label: "Subtitle",
      },
      {
        fieldName: "categoryDisplayType",
        fieldType: FieldType.enumeration,
        label: "Category Display Type",
        options: getDisplayTypeOptions(),
        width: "160px",
        visible: isSeriesChartField,
      },
      {
        fieldName: "valueDisplayType",
        fieldType: FieldType.enumeration,
        label: "Value Display Type",
        options: getDisplayTypeOptions(),
        width: "160px",
        visible: isSeriesChartField,
      },
      {
        fieldName: "height",
        label: "Height",
        width: "80px",
      },
      {
        fieldName: "width",
        label: "Width",
        width: "80px",
      },
      {
        fieldName: "selectable",
        fieldType: FieldType.boolean,
        label: "Is Selectable",
        visible: "this.visualType === 'table'",
        hints: "If checked, the table row is selectable as filter to driven data queries of other tiles.",
      },
      {
        fieldName: "filterName",
        label: "Filter Name",
        hints: "Parameter name used when selected as filter",
        visible: isInputField + " || this.selectable",
      },
      {
        fieldName: "columnsAsSelectedValues",
        fieldType: FieldType.textArray,
        label: "Columns As Selected Values",
        visible: "this.selectable",
        hints:
          "If the tile is set as selectable, specify here a comma-separated list of column field names that are used as the selected values.",
      },
      {
        fieldName: "ignoreSubmitButton",
        fieldType: FieldType.boolean,
        label: "Ignore Submit Button",
        visible: "this.selectable",
        hints: "Ignore the submit button for this tile even if 'Use Submit Button' is set for the report.",
      },
      {
        fieldName: "stacked",
        fieldType: FieldType.boolean,
        label: "Stacked Series",
        hints: "Stack up the multi series chart",
        visible: isSeriesChartField,
      },
      {
        fieldName: "lastInRow",
        fieldType: FieldType.boolean,
        label: "Last In Row",
        hints: "As last field in a row, a row will start for subsequent fields.",
      },
      {
        fieldName: "includeUndefinedColumns",
        fieldType: FieldType.boolean,
        label: "Include Undefined Columns",
        visible: "this.visualType === 'table'",
        hints:
          "If checked, all fields from the query result will be included as columns, even the Columns definition is explicitly set.",
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.context,
        visible: isSeriesChartField,
        width: "100%",
        fields: [
          {
            fieldName: "margin",
            fieldType: FieldType.context,
            fields: [
              {
                fieldName: "left",
                fieldType: FieldType.number,
                label: "Margin Left",
                width: "70px",
                valueWidth: "60px",
              },
              {
                fieldName: "bottom",
                fieldType: FieldType.number,
                label: "Bottom",
                width: "60px",
              },
              {
                fieldName: "right",
                fieldType: FieldType.number,
                label: "Right",
                width: "60px",
              },
              {
                fieldName: "top",
                fieldType: FieldType.number,
                label: "Top",
                width: "60px",
              },
            ],
          },
          {
            fieldName: "colors",
            fieldType: FieldType.textArray,
            label: "Colors",
            width: "100px",
            hints: "Specify a comma-separate list of CSS colors for the data series, e.g. #30AA00,red,green",
          },
          {
            fieldName: "alwaysHideLegend",
            fieldType: FieldType.boolean,
            label: "Always Hide Legend",
            hints: "If this is set, 'Always Show Legend' field will be ignored.",
          },
          {
            fieldName: "alwaysShowLegend",
            fieldType: FieldType.boolean,
            label: "Always Show Legend",
            hints:
              "By default, legends will be displayed only when more than one series are detected. Checking this will always show the legend even for a single series.",
          },
          {
            fieldName: "legendPosition",
            fieldType: FieldType.enumeration,
            label: "Legend Position",
            options: getLegendPositionOptions(),
            width: "120px",
            hints: "Select a position for where the legends should be placed",
          },
          {
            fieldName: "tickLabelAngle",
            fieldType: FieldType.number,
            label: "X-Axis Label Angle",
            width: "120px",
            valueWidth: "60px",
            hints: "Specify the angle of the x-Axis labels, default to -90. 0 is horizontal.",
          },
          {
            fieldName: "showValueLabels",
            fieldType: FieldType.boolean,
            label: "Show Value Labels",
            hints: "Display the value labels for each datapoint.  Not all visual types support this.",
          },
          {
            fieldName: "tooltipProps",
            fieldType: FieldType.context,
            fields: [
              {
                fieldName: "displayType",
                fieldType: FieldType.enumeration,
                label: "Tooltip Value Display Type",
                options: getDisplayTypeOptions(),
                width: "160px",
              },
              {
                fieldName: "decimal",
                fieldType: FieldType.number,
                label: "Tooltip Value Decimal",
                width: "120px",
                valueWidth: "60px",
              },
            ],
          },
          {
            fieldName: "xAxisStyle",
            fieldType: FieldType.json,
            label: "X-Axis CSS Style",
            width: "45%",
            hints: "Specify the CSS style object for customizing the x-Axis labels, e.g. {'fontSize':'16px'}",
          },
          {
            fieldName: "yAxisStyle",
            fieldType: FieldType.json,
            label: "Y-Axis CSS Style",
            width: "45%",
            hints: "Specify the CSS style object for customizing the y-Axis labels, e.g. {'fontSize':'16px'}",
          },
        ],
      },
      {
        fieldName: "visualProps",
        fieldType: FieldType.json,
        label: "Visual Properties",
        width: "60%",
        visible: "this.visualType !== 'diagram'",
        lastInRow: true,
      },
      {
        fieldName: "style",
        fieldType: FieldType.json,
        label: "Tile Style",
        width: "100%",
        lastInRow: true,
        hints: "Specify CSS styles for the tile",
      },
      {
        fieldName: "visibilityConditions",
        fieldType: FieldType.json,
        width: "50%",
        label: "Visibility Conditions",
        lastInRow: true,
        hints:
          'Specify an array of name/value pairs on filter name and value to indicate if the tile should be visible or not. E.g. if you have a filter name "someType", you can use set [{"name": "someType", "value": ["myFilterValue1", "myFilterValue2"]}] to show the tile if the value of someType equals to myFilterValue1 or myFilterValue2.',
      },
      {
        fieldName: "diagramNodesQuery",
        label: "Dynamic Nodes Query",
        fieldType: FieldType.text,
        width: "100%",
        allowFullEditor: true,
        visible: "this.visualType === 'diagram'",
        hints: "Dynamically returns diagram nodes definition according to the nodes schema.",
      },
      {
        fieldName: "diagramLinksQuery",
        label: "Dynamic Links Query",
        fieldType: FieldType.text,
        width: "100%",
        allowFullEditor: true,
        visible: "this.visualType === 'diagram'",
        hints: "Dynamically returns diagram links definition according to the links schema.",
      },
    ].concat(getTileDataSourceFields({}, "true", tiles)),
  },
  {
    label: "Advanced",
    fieldType: FieldType.container,
    collapsible: true,
    compact: true,
    visible: `this.visualType === 'actionButton'`,
    fields: [
      {
        fieldName: "lastInRow",
        fieldType: FieldType.boolean,
        label: "Last In Row",
        hints: "As last field in a row, a row will start for subsequent fields.",
      },
      {
        fieldName: "style",
        fieldType: FieldType.json,
        label: "Tile Style",
        width: "100%",
        lastInRow: true,
        hints: "Specify CSS styles for the tile",
      },
    ].concat(
      getTileDataSourceFields(
        {},
        "true",
        tiles,
        DataSourceTypeOptions.concat([{ key: "radarConfigWrite", text: "Radar Config Write" }])
      )
    ),
  },
  { ...getColumnFields(), visible: `(${isNotInputField}) && (${isVisualTile})` },
];

const getRollingIntervalOptions = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in RollingIntervalOptions) {
    options.push(makeOption(RollingIntervalOptions[value]));
  }
  return options;
};

export const getCommonStatsFields = (): IField[] => {
  return [
    {
      fieldName: "timeInterval",
      label: "Time Interval",
      fieldType: FieldType.text,
      width: "100px",
      valueWidth: "60px",
      regEx: "^([0-9]+([.][0-9]+)?[dhmy])?$",
      hints: "Should be a number followed a letter (h=hours, d=days, m=month, y=year). For example: '2d'",
      visible: "(!this.startTime || !this.endTime) && !this.rollingInterval",
    },
    {
      fieldName: "runInterval",
      label: "Run Interval",
      fieldType: FieldType.text,
      width: "100px",
      valueWidth: "60px",
      regEx: "^([0-9]+([.][0-9]+)?[dhmy])?$",
      hints:
        "Should be a number followed a letter (h=hours, d=days, m=month, y=year). For example: '2d'. Use when we need a run interval different from the time interval",
      visible: "(!this.startTime || !this.endTime) && !this.rollingInterval",
    },
    {
      fieldName: "rollingInterval",
      label: "Rolling Interval",
      fieldType: FieldType.enumeration,
      width: "160px",
      options: [...getRollingIntervalOptions(), makeOption("")],
      hints: "MTD=Month To Date, QTD=Quarter To Date, YTD=Year To Date",
      visible: "!this.startTime || !this.endTime",
    },
    {
      fieldName: "startTime",
      label: "Start Time",
      fieldType: FieldType.text,
      width: "160px",
      regEx: "^([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])?$",
      hints: "Date in the form of (yyyy-mm-dd)",
    },
    {
      fieldName: "endTime",
      label: "End Time",
      fieldType: FieldType.text,
      width: "160px",
      regEx: "^([0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9])?$",
      hints: "Date in the form of (yyyy-mm-dd)",
    },
    {
      fieldName: "monthValueDiff",
      label: "Month Value Diff",
      fieldType: FieldType.number,
      visible: "!this.statsType || this.statsType === 'EntityMetrics'",
      width: "120px",
      valueWidth: "60px",
      hints: "Specify optional number of month difference in calculation for the time range.",
    },
    {
      fieldName: "priorMonthContinuationDays",
      label: "Prior Month Continuation Days",
      fieldType: FieldType.number,
      visible: "!this.statsType || this.statsType === 'EntityMetrics'",
      width: "200px",
      valueWidth: "60px",
      hints:
        "Specify optional number of days after the prior month has passed to calculate the result for the prior month.",
    },
    {
      fieldName: "overrideResult",
      label: "Override Result",
      fieldType: FieldType.boolean,
      hints: "Indicate if new result with the same id and time range should be overridden or not.",
    },
  ];
};

export const AzureTenants = [
  {
    key: "PME",
    text: "PME",
  },
  {
    key: "CorpNet",
    text: "CorpNet",
  },
];

export const getTileDataSourceFields = (
  configObject: IDataSource = {},
  visible: string = "true",
  tiles = null,
  dataSourceTypeOptions = DataSourceTypeOptions
): any[] => {
  let tileOptions =
    tiles &&
    tiles.map((tile, index) => ({ key: tile.tileId, text: `Tile #${index + 1} ${tile.name || tile.filterName}` }));

  let dataSourceTypeOptionsFinal = tileOptions
    ? [...dataSourceTypeOptions]
    : dataSourceTypeOptions.filter((dsType) => dsType.key !== "tileDataSource");

  dataSourceTypeOptionsFinal.unshift({ key: "", text: "" });

  return [
    {
      fieldName: "dataSourceType",
      label: "Data Source Type",
      fieldType: FieldType.enumeration,
      options: dataSourceTypeOptionsFinal,
      value: configObject.dataSourceType,
      width: "200px",
      visible,
    },
    {
      fieldName: "cluster",
      label: "Kusto Cluster URL",
      value: configObject.cluster,
      width: "60%",
      hints: "By default, the query is executed against Commerce Radar's default cluster.",
      visible: `(!this.dataSourceType || this.dataSourceType === 'kusto') && (${visible})`,
    },
    {
      fieldName: "database",
      label: "Kusto Database Name",
      value: configObject.database,
      hints: "By default, the query is executed against Commerce Radar's default database.",
      visible: `(!this.dataSourceType || this.dataSourceType === 'kusto') && (${visible})`,
    },
    {
      fieldName: "keyVaultSecretName",
      label: "Password KeyVault Secret Name",
      value: configObject.keyVaultSecretName,
      width: "300px",
      visible: `this.dataSourceType === 'sql' && (${visible})`,
      hints:
        "Contact the Commerce Radar team with alias comradhelp and onboard your password as Azure KeyVault secret.",
    },
    {
      fieldName: "connectionString",
      label: "Connection String",
      value: configObject.connectionString,
      lastInRow: true,
      regEx: /^((?!password\s*=).)*$/gi,
      visible: `this.dataSourceType === 'sql' && (${visible})`,
      hints:
        "Note: Password is not allowed in this field. Instead, contact the Commerce Radar team with alias comradhelp and onboard your password as Azure KeyVault secret.",
    },
    {
      fieldName: "keyVaultTenant",
      label: "Secret URI KeyVault Tenant",
      fieldType: FieldType.enumeration,
      options: AzureTenants,
      value: configObject.keyVaultTenant,
      width: "200px",
      visible: `this.dataSourceType === 'sql' && (${visible})`,
      hints: "Choose Azure Tenant for the KeyVault Secret URI/identifier. Default tenant is PME",
    },
    {
      fieldName: "connectionStringSecretUri",
      label: "Connection String Secret URI/Identifier",
      value: configObject.connectionStringSecretUri,
      lastInRow: true,
      visible: `this.dataSourceType === 'sql' && (${visible})`,
      hints:
        "Define the KeyVault Secret URI/identifier for your connection string.  If this is defined, the Connection String field above will be ignored. Here is an example: https://mykeyvault.vault.azure.net/secrets/MyConnStrSecretName/2ef7cb1c95024758bf47683f3f9f7649",
    },
    {
      fieldName: "appId",
      label: "App ID",
      value: configObject.appId,
      visible: `this.dataSourceType === 'appInsights' && (${visible})`,
      width: "300px",
      hints:
        "Application ID for App Insights REST API.  See https://dev.applicationinsights.io/documentation/Authorization",
    },
    {
      fieldName: "keyVaultSecretName",
      label: "AppInsights API Key KeyVault Secret Name",
      value: configObject.keyVaultSecretName,
      visible: `this.dataSourceType === 'appInsights' && (${visible})`,
      width: "300px",
      hints:
        "This is the KeyVault Secret Name that stores the API Key for App Insights REST API.  See https://dev.applicationinsights.io/documentation/Authorization for what is an API Key.  Contact the Commerce Radar team with alias comradhelp and onboard your API Key as Azure KeyVault secret.",
    },
    {
      fieldName: "endpointUrl",
      label: "DevOps Org URL",
      value: configObject.endpointUrl,
      width: "400px",
      visible: `this.dataSourceType === 'devOps' && (${visible})`,
      hints:
        "By default, the org url is pointing to https://microsoft.visualstudio.com.  Specify the org url here if it's different from the Microsoft org.",
    },
    {
      fieldName: "keyVaultSecretName",
      label: "DevOps PAT KeyVault Secret Name",
      value: configObject.keyVaultSecretName,
      width: "300px",
      visible: `this.dataSourceType === 'devOps' && (${visible})`,
      hints:
        "Contact the Commerce Radar team with alias comradhelp and onboard your DevOps PAT as Azure KeyVault secret.",
    },
    {
      fieldName: "workspaceId",
      label: "Workspace ID",
      value: configObject.workspaceId,
      visible: `this.dataSourceType === 'logAnalytics' && (${visible})`,
      width: "300px",
      hints: "Workspace ID of the target Azure Log Analytics Workspace",
    },
    {
      fieldName: "endpointUrl",
      label: "Cosmos DB Endpoint URL",
      value: configObject.endpointUrl,
      width: "400px",
      visible: `(this.dataSourceType === 'cosmosDbSql' || this.dataSourceType === 'cosmosDbSproc') && (${visible})`,
    },
    {
      fieldName: "database",
      label: "Database ID",
      value: configObject.database,
      visible: `(this.dataSourceType === 'cosmosDbSql' || this.dataSourceType === 'cosmosDbSproc') && (${visible})`,
    },
    {
      fieldName: "container",
      label: "Container ID",
      value: configObject.container,
      visible: `(this.dataSourceType === 'cosmosDbSql' || this.dataSourceType === 'cosmosDbSproc') && (${visible})`,
    },
    {
      fieldName: "keyVaultSecretName",
      label: "Primary Key KeyVault Secret Name",
      value: configObject.keyVaultSecretName,
      width: "400px",
      visible: `(this.dataSourceType === 'cosmosDbSql' || this.dataSourceType === 'cosmosDbSproc') && (${visible})`,
      hints:
        "This is the KeyVault Secret Name that stores the Primary Key for Cosmos DB.  Contact the Commerce Radar team with alias comradhelp and onboard your Cosmos DB Primary Key as Azure KeyVault secret.",
    },
    {
      fieldName: "partitionKey",
      label: "Partition Key",
      value: configObject.partitionKey,
      visible: `this.dataSourceType === 'cosmosDbSproc' && (${visible})`,
      hints: "Partition key for your stored procedure call.",
    },
    {
      fieldName: "dataFields",
      label: "Parameters",
      value: configObject.dataFields,
      visible: `this.dataSourceType === 'cosmosDbSproc' && (${visible})`,
      hints:
        "Comma-separated text list of parameters for your stored procedure call.  You can use placeholders from other filter fields here.",
    },
    {
      fieldName: "targetTileId",
      fieldType: FieldType.enumeration,
      label: "Use Data Source of This Tile",
      options: tileOptions,
      value: configObject.targetTileId,
      width: "400px",
      visible: `this.dataSourceType === 'tileDataSource' && (${visible})`,
      hints:
        "Select one of your other tiles and use that data source for this tile.  This improves query performance to minimize duplicated executions of the same query.",
    },
    {
      fieldName: "container",
      label: "Container ID",
      value: configObject.container,
      width: "200px",
      visible: `(this.dataSourceType === 'radarConfigRead' || this.dataSourceType === 'radarConfigWrite') && (${visible})`,
      hints: "Enter one of the supported container ID in Radar, i.e. config type",
    },
    {
      fieldName: "dataFields",
      label: "Data Fields To Return",
      value: configObject.dataFields,
      width: "200px",
      visible: `this.dataSourceType === 'radarConfigRead' && (${visible})`,
      regEx: /^\w+(\s*,\s*\w+)*$/,
      hints:
        "Comma-separated list of field names to query and return.  By default, it returns 'id as key, name as text'.",
    },
    {
      fieldName: "selectedId",
      label: "Selected Config ID",
      value: configObject.selectedId,
      width: "100px",
      visible: `this.dataSourceType === 'radarConfigWrite' && (${visible})`,
      hints:
        "The config ID to update this data source with.  You can refer to other field value, e.g. {itemId} if you have another input tile with itemId as field name.",
    },
    {
      fieldName: "endpointUrl",
      label: "Metrics Endpoint URL",
      value: configObject.endpointUrl,
      visible: `this.dataSourceType === 'genevaMetricKqlm' && (${visible})`,
      width: "300px",
      hints: "Endpoint URL for Geneva Metric",
    },
    {
      fieldName: "appId",
      label: "Account",
      value: configObject.appId,
      visible: `this.dataSourceType === 'genevaMetricKqlm' && (${visible})`,
      width: "300px",
      hints: "Account name for Geneva Metric",
    },
    {
      fieldName: "workspaceId",
      label: "Namespace",
      value: configObject.workspaceId,
      visible: `this.dataSourceType === 'genevaMetricKqlm' && (${visible})`,
      width: "300px",
      hints: "Metric namespace for Geneva Metric",
    },
    {
      fieldName: "timeAgo",
      label: "Time Ago",
      value: configObject.timeAgo,
      visible: `this.dataSourceType === 'genevaMetricKqlm' && (${visible})`,
      width: "60px",
      hints:
        "Time range for the query. 30d for data since 30 days ago, 4h for data since 4 hours ago. Use standard placeholder for value from another filter, e.g. {timeAgo} if another filter with name 'timeAgo' is defined for this value.",
    },
    {
      fieldName: "appId",
      label: "Resource ID",
      value: configObject.appId,
      visible: `this.dataSourceType === 'webApi' && (${visible})`,
      width: "300px",
      hints: "Resource id of the target Web API app",
    },
  ];
};

export const getColumnFields = (propsOverride: object = {}): IField => {
  return {
    fieldName: "columns",
    label: "Columns",
    fieldType: FieldType.items,
    compact: true,
    itemTypeName: "Column",
    customTabHeader: (dataContext: IDataContext, itemTypeName: string, index: number) => {
      const column = dataContext?.context?.columns?.length > index && dataContext.context.columns[index];
      return column.fieldName;
    },
    customTitle: (dataContext: IDataContext, itemTypeName: string, index: number) => {
      const column = dataContext?.context?.columns?.length > index && dataContext.context.columns[index];
      return column.name;
    },
    fields: [
      {
        fieldName: "fieldName",
        label: "Field Name",
        required: true,
      },
      {
        fieldName: "name",
        label: "Column Name",
        required: true,
      },
      {
        fieldName: "displayType",
        fieldType: FieldType.enumeration,
        label: "Display Format",
        options: getDisplayTypeOptions(),
        width: "160px",
      },
      {
        fieldName: "minWidth",
        fieldType: FieldType.number,
        label: "Min Width",
        width: "0px",
      },
      {
        fieldName: "maxWidth",
        fieldType: FieldType.number,
        label: "Max Width",
        width: "60px",
      },
      {
        fieldName: "template",
        label: "Template",
        // eslint-disable-next-line no-template-curly-in-string
        hints: "Use ${this.fieldName} to refer to your field value",
        allowFullEditor: true,
        lastInRow: true,
      },
      {
        label: "Advanced",
        fieldType: FieldType.container,
        collapsible: true,
        compact: true,
        fields: [
          {
            fieldName: "locale",
            label: "Locale",
            hints: "Use browser locale standard, default to 'en-us'",
            regEx: "^([\\w]{2})-([\\w]{2})$",
            width: "70px",
          },
          {
            fieldName: "localeFieldName",
            label: "Locale Field Name",
          },
          {
            fieldName: "currency",
            label: "Currency",
            hints: "Use browser currency standard, default to 'USD'",
            regEx: "^([\\w]{3})$",
            width: "70px",
          },
          {
            fieldName: "currencyFieldName",
            label: "Currency Field Name",
          },
          {
            fieldName: "decimal",
            fieldType: FieldType.number,
            label: "Decimal",
            width: "50px",
          },
          {
            fieldName: "decimalFieldName",
            label: "Decimal Field Name",
          },
          {
            fieldName: "isSorted",
            fieldType: FieldType.boolean,
            label: "Is Sorted",
          },
          {
            fieldName: "isSortedDescending",
            fieldType: FieldType.boolean,
            label: "Sorted Descending",
          },
          {
            fieldName: "isExtraColumn",
            fieldType: FieldType.boolean,
            label: "Extra Column",
            hints: "Check if this column is considered extra in additional to the default columns.",
          },
          {
            fieldName: "columnPosition",
            fieldType: FieldType.number,
            label: "Column Position",
            valueWidth: "40px",
            hints: "Specify an integer for the position index of the extra column.",
          },
          {
            fieldName: "headerStyle",
            fieldType: FieldType.json,
            label: "Header CSS Style",
            hints: "CCS styling for column header",
            width: "49%",
          },
          {
            fieldName: "style",
            fieldType: FieldType.json,
            label: "Data CSS Style",
            hints: "CCS styling for data cell",
            lastInRow: true,
          },
        ],
      },
    ],
    ...propsOverride,
  };
};

export const getDiagramAdvancedFields = (diagramView) => ({
  label: "Advanced",
  fieldType: FieldType.container,
  collapsible: true,
  fields: [
    {
      fieldName: "height",
      fieldType: FieldType.number,
      label: "Height",
      value: diagramView.height,
    },
    {
      fieldName: "width",
      fieldType: FieldType.number,
      label: "Width",
      value: diagramView.width,
    },
    {
      fieldName: "hideDiagram",
      fieldType: FieldType.boolean,
      label: "Hide Diagram",
      value: diagramView.hideDiagram,
    },
    {
      fieldName: "defaultNodeProps",
      fieldType: FieldType.json,
      label: "Default Node Properties",
      value: diagramView.defaultNodeProps,
      width: "100%",
    },
    {
      fieldName: "defaultLinkProps",
      fieldType: FieldType.json,
      label: "Default Link Properties",
      value: diagramView.defaultLinkProps,
      width: "100%",
    },
  ],
});

export const getDiagramNodeFields = (nodes: INode[]) => ({
  fieldName: "nodes",
  label: "Nodes",
  fieldType: FieldType.items,
  value: nodes,
  itemTypeName: "Node",
  itemDisplayView: ItemDisplayView.list,
  collapsed: true,
  fields: [
    {
      fieldName: "id",
      label: "Entity ID",
      required: true,
      width: "300px",
    },
    {
      fieldName: "name",
      label: "Display Name",
    },
    {
      fieldName: "x",
      label: "Position X",
      fieldType: FieldType.number,
      width: "60px",
    },
    {
      fieldName: "y",
      label: "Position Y",
      fieldType: FieldType.number,
      width: "60px",
    },
    {
      label: "Advanced",
      fieldType: FieldType.container,
      collapsible: true,
      compact: true,
      fields: [
        {
          fieldName: "height",
          fieldType: FieldType.number,
          label: "Height",
        },
        {
          fieldName: "width",
          fieldType: FieldType.number,
          label: "Width",
        },
        {
          fieldName: "tooltip",
          label: "Tooltip Text",
          allowFullEditor: true,
          width: "100%",
        },
        {
          fieldName: "style",
          fieldType: FieldType.json,
          label: "Style",
          width: "100%",
        },
      ],
    },
  ],
});

export const getDiagramLinkFields = (links) => ({
  fieldName: "links",
  label: "Links",
  fieldType: FieldType.items,
  value: links,
  itemTypeName: "Link",
  itemDisplayView: ItemDisplayView.list,
  collapsed: true,
  fields: [
    {
      fieldName: "fromNodeId",
      label: "Source Entity ID",
      required: true,
      width: "300px",
    },
    {
      fieldName: "toNodeId",
      label: "Target Entity ID",
      required: true,
      width: "300px",
    },
    {
      label: "Advanced",
      fieldType: FieldType.container,
      collapsible: true,
      compact: true,
      fields: [
        {
          fieldName: "animation",
          fieldType: FieldType.boolean,
          label: "Animation",
        },
        {
          fieldName: "lineMarkerType",
          fieldType: FieldType.enumeration,
          label: "Line Marker Type",
          options: getLineMarkerTypeOptions(),
          width: "160px",
        },
        {
          fieldName: "lineType",
          fieldType: FieldType.enumeration,
          label: "Line Type",
          options: getLineTypeOptions(),
          width: "160px",
        },
        {
          fieldName: "style",
          fieldType: FieldType.json,
          label: "Style",
          width: "100%",
        },
      ],
    },
  ],
});

export const DataSourceTypeOptions = [
  {
    key: "appInsights",
    text: "App Insights",
  },
  {
    key: "devOps",
    text: "Azure DevOps",
  },
  {
    key: "cosmosDbSql",
    text: "Cosmos DB SQL",
  },
  {
    key: "cosmosDbSproc",
    text: "Cosmos DB Stored Proc",
  },
  {
    key: "genevaMetricKqlm",
    text: "Geneva Metric KQL-M",
  },
  {
    key: "icmKusto",
    text: "IcM Kusto",
  },
  {
    key: "icmRestApi",
    text: "IcM REST API",
  },
  {
    key: "kusto",
    text: "Kusto",
  },
  {
    key: "logAnalytics",
    text: "Log Analytics",
  },
  {
    key: "radarApi",
    text: "Radar Api",
  },
  {
    key: "radarAppInsights",
    text: "Radar App Insights",
  },
  {
    key: "radarConfigRead",
    text: "Radar Config Read",
  },
  {
    key: "sql",
    text: "SQL",
  },
  {
    key: "tileDataSource",
    text: "Tile Data Source",
  },
  {
    key: "webApi",
    text: "Web Api",
  },
];

export const getInvalidStagingConfigError = (classNames, configItemName) => (
  <div className={`${classNames.errorDetails} ${classNames.appModuleContent}`}>
    System Management for {configItemName} is only available in Live mode. Please switch back to Live mode to manage
    this config type, or select another config type to manage in Staging mode.
  </div>
);

export function checkEachValue(
  arr: object[],
  currentObject: object,
  value: string,
  checkValue: (currentObject: object, value: string) => boolean
): boolean {
  let foundBadValue = false;
  arr?.length &&
    arr.forEach((objectToCheck) => {
      if (objectToCheck !== currentObject) {
        foundBadValue = foundBadValue || !checkValue(objectToCheck, value);
      }
    });
  return !foundBadValue;
}

export function checkIfUnique(
  dataContext: IDataContext,
  value,
  arrayNodeName: string,
  propertyName: string = "name"
): IFieldStatus {
  const obj = dataContext.context;
  const objectArray = dataContext?.parentDataContext?.context && dataContext?.parentDataContext?.context[arrayNodeName];
  try {
    const valid = checkEachValue(
      objectArray,
      obj,
      value,
      (objectToCheck: object, value) => objectToCheck[propertyName] !== value
    );
    return {
      passed: valid,
      message: valid ? "" : `Invalid: ${propertyName} must be different than any others in ${arrayNodeName}.`,
    };
  } catch (error) {
    return {
      passed: false,
      message: `Error in code: ${error}`,
    };
  }
}

export function makeOption(value: string, textOverride?: string) {
  const textValue = textOverride === undefined || textOverride === null ? value : textOverride;
  const text = textValue === "blank" || textValue === "" ? "[None Selected]" : textValue;
  return {
    key: value === "blank" ? "" : value,
    text,
    ariaLabel: text,
  } as IDropdownOption;
}
