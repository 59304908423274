import React, { useEffect, useState } from "react";
import { fetchCdtTestRuns } from "../../api/radarApi";
import classNames from "./CdtResults.module.scss";
import TileDetailsList from "../../shared/components/Tile/TileDetailsList";
import { CheckboxVisibility, IColumn, SelectionMode } from "@fluentui/react";

export const CdtResults = () => {
  const [testRuns, setTestRuns] = useState<any[]>(undefined);

  useEffect(() => {
    fetchCdtTestRuns().then((runs: any) => setTestRuns(runs));
  }, []);

  return (
    <TileDetailsList
      className={classNames.root}
      title={"CDT Recent Runs"}
      showSearchBox
      classNameForDetailsList={classNames.list}
      items={testRuns}
      columns={columns}
      compact={false}
      selectionMode={SelectionMode.none}
      checkboxVisibility={CheckboxVisibility.hidden}
      noItemText={"No test run data."}
    />
  );
};

export default CdtResults;

const columns: IColumn[] = [
  {
    key: "passed",
    name: "Passed",
    fieldName: "passed",
    minWidth: 50,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "runBy",
    name: "Run By",
    fieldName: "runBy",
    minWidth: 240,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "runId",
    name: "Run ID",
    fieldName: "runId",
    minWidth: 240,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "templateId",
    name: "Template ID",
    fieldName: "templateId",
    minWidth: 240,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "templateName",
    name: "Template Name",
    fieldName: "templateName",
    minWidth: 240,
    isResizable: true,
    onRender: (item) => item["templateName"] ?? templateNameMappings[item["templateId"]],
  },
];

const templateNameMappings = {
  "0fb3d34a-7251-4b3c-a154-3435269e309b": "Purchase Perpetual Products (Windows) Full Refund",
  "10576b06-e210-4fe2-8834-7e4e5ec60368": "Immediate Rev Rec",
  "28b9c0e5-86ef-46b5-817a-8ee6b03e1d59": "Immediate Rev Rec - Logical Order",
  "2d3ed186-8d94-4205-9599-ebd49b4fa30a": "Windows_PerpetualLicensePurchase_Unbilled - Orders Last",
  "342c6279-3bf2-4634-94cf-9462536a7c80": "Purchase Perpetual Products (Windows) Full Refund - Orders First",
  "34801835-cc65-4024-8f61-58070b229181": "Void and Rebill Template 3: Variation 1",
  "36bdc200-bcd3-4935-8255-7d0726900e0c": "Purchase Perpetual Products (Windows) Full Refund - Logical Order",
  "4f011b13-3ebd-4403-a3c3-72d7c4c431fd": "Purchase Perpetual Products (Windows) Full Refund - Orders Last",
  "77accef7-5a4f-460f-b3f1-1cf17a985f38": "Windows_PerpetualLicensePurchase_Unbilled - Logical Order",
  "7af5f6e1-e427-42c2-9243-8e6782fb9b42": "Purchase_Dynamics_Cancel_Immediate",
  "87d2a117-951f-455b-a854-d853afbebded": "Immediate Rev Rec - Orders Last",
  "914aee26-7091-4870-9c2d-5ba57d528a83": "Purchase_Dynamics_Cancel_Immediate - Orders Last",
  "95be10eb-3665-4f57-ac8f-284ea488fd72": "Void and Rebill Template 3: Variation 2",
  "a089b95a-d969-41fb-af19-91c01c62012d": "Purchase_Dynamics_Cancel_Immediate - Logical Order",
  "bc3bb891-6434-4ade-b80e-93878df905ca": "Void and Rebill Template 3",
  "bcde2bda-d65b-41da-8bbb-93ec4f1958dc": "Windows_PerpetualLicensePurchase_Unbilled",
  "c1a7cd7d-f163-4756-ab1a-c615dfa52e23": "Void and Rebill Template 3: Variation 3",
  "d487fcc3-2e41-4a84-9484-8a2cec978ad6": "Windows_PerpetualLicensePurchase_Unbilled - Orders First",
  "d9a12aa1-4ca8-4fb1-8980-a6dcbd374d3c": "Purchase_Dynamics_Cancel_Immediate - Orders First",
  "f02c04b7-011b-4db6-b87b-d6cf80e29492": "Immediate Rev Rec - Orders First",
};
