import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { actionCreator as appActionCreator } from "../../../app/duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import {
  defaultTeam,
  getFields,
  getOkrFields,
  getHealthMetricsFields,
  getFmeCloseIssuesFields,
  getStreamsFields,
  getTeamDataSourcesFields,
} from "./MyTeamEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { parseJsonSafe } from "../../../shared/utilities/miscHelper";
import { ITeam, ITemplate } from "../../common/interfaces";
import { IState } from "../../../reducers/interfaces";
import { IHealthMetricGroup, IHealthMetricType } from "../../home/HealthDashboard.types";
import { ConfigItemType } from "../../../api/radarApi";
import TestDataSourceModal from "../common/TestDataSourceModal";

export interface IMyTeamEditStateProps extends IConfigItemEditProps {
  standardTemplates: ITemplate[];
  healthMetricTypes: IHealthMetricType[];
  healthMetricGroups: IHealthMetricGroup[];
  teams: ITeam[];
}

export interface IMyTeamEditDispatchProps extends IConfigItemEditDispatchProps {
  loadStandardTemplates: () => void;
  loadHealthMetricTypes: () => void;
  loadHealthMetricGroups: () => void;
  loadTeams: () => void;
}

export const MyTeamEdit = (props: IMyTeamEditStateProps & IMyTeamEditDispatchProps) => {
  const generalEditForm: React.RefObject<any> = React.createRef();
  const okrsEditForm: React.RefObject<any> = React.createRef();
  const healthMetricsEditForm: React.RefObject<any> = React.createRef();
  const fmeCloseIssuesEditForm: React.RefObject<any> = React.createRef();
  const streamsEditForm: React.RefObject<any> = React.createRef();
  const teamDataSourcesEditForm: React.RefObject<any> = React.createRef();

  const {
    standardTemplates,
    healthMetricTypes,
    healthMetricGroups,
    teams,
    configValue,
    editConfigItem,
    loadStandardTemplates,
    loadHealthMetricTypes,
    loadHealthMetricGroups,
    loadTeams,
  } = props;

  const [showTestResultDialog, setShowTestResultDialog] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<object>();
  const [testDataSourcePivot, setTestDataSourcePivot] = useState<string>();
  const configItem = useMemo(() => parseJsonSafe(configValue), [configValue]);

  useEffect(() => {
    if (!standardTemplates || !standardTemplates.length) {
      loadStandardTemplates();
    }

    if (!healthMetricTypes || !healthMetricTypes.length) {
      loadHealthMetricTypes();
    }

    if (!healthMetricGroups || !healthMetricGroups.length) {
      loadHealthMetricGroups();
    }

    if (!teams?.length) {
      loadTeams();
    }
    // eslint-disable-next-line
  }, []);

  const onTestDataSource = useCallback((targetDataSource, selectedPivot) => {
    setShowTestResultDialog(true);
    setDataSource(targetDataSource);
    setTestDataSourcePivot(selectedPivot);
  }, []);

  const getFieldErrors = () => {
    var generalEditFormErrors = generalEditForm?.current?.getErrors();
    var okrsEditFormErrors = okrsEditForm?.current?.getErrors();
    var healthMetricsEditFormErrors = healthMetricsEditForm?.current?.getErrors();
    var fmeCloseIssuesEditFormErrors = fmeCloseIssuesEditForm?.current?.getErrors();
    var streamsEditFormErrors = streamsEditForm?.current?.getErrors();

    return {
      ...generalEditFormErrors,
      ...okrsEditFormErrors,
      ...healthMetricsEditFormErrors,
      ...fmeCloseIssuesEditFormErrors,
      ...streamsEditFormErrors,
    };
  };

  return (
    <>
      <ConfigItemEdit
        {...props}
        configItem={configItem}
        configItemName="Team"
        configItemType={ConfigItemType.Teams}
        appInsightsPageName="MyTeamEditPage"
        leftNavUrl={leftNavUrls.management.team}
        defaultConfigItem={defaultTeam}
        getFieldErrors={getFieldErrors}
        pivotItems={[
          <PivotItem key="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem)}
              editConfigItem={editConfigItem}
              ref={generalEditForm}
            />
          </PivotItem>,
          <PivotItem key="okrs" className={classNames.pivotItem} headerText="OKRs">
            <ConfigItemEditForm
              configItem={configItem}
              fields={[getOkrFields(configItem?.okrs)]}
              editConfigItem={editConfigItem}
              ref={okrsEditForm}
            />
          </PivotItem>,
          <PivotItem key="healthMetrics" className={classNames.pivotItem} headerText="Health Metrics">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getHealthMetricsFields(
                configItem,
                standardTemplates,
                healthMetricTypes,
                healthMetricGroups,
                (targetDataSource) => onTestDataSource(targetDataSource, "healthMetrics")
              )}
              editConfigItem={editConfigItem}
              ref={healthMetricsEditForm}
            />
          </PivotItem>,
          <PivotItem key="fmeCloseIssues" className={classNames.pivotItem} headerText="FME Close Issues">
            <ConfigItemEditForm
              configItem={configItem}
              fields={[getFmeCloseIssuesFields(configItem?.fmeCloseIssueSets)]}
              editConfigItem={editConfigItem}
              ref={fmeCloseIssuesEditForm}
            />
          </PivotItem>,
          <PivotItem key="streams" className={classNames.pivotItem} headerText="Streams">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getStreamsFields(configItem, teams, (targetDataSource) =>
                onTestDataSource(targetDataSource, "streams")
              )}
              editConfigItem={editConfigItem}
              ref={streamsEditForm}
            />
          </PivotItem>,
          <PivotItem key="teamDataSources" className={classNames.pivotItem} headerText="Data Sources">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getTeamDataSourcesFields(configItem, onTestDataSource)}
              editConfigItem={editConfigItem}
              ref={teamDataSourcesEditForm}
            />
          </PivotItem>,
        ]}
      />
      <TestDataSourceModal
        hidden={!showTestResultDialog}
        dataSource={dataSource}
        useQueryParameters={testDataSourcePivot === "healthMetrics"}
        onDismiss={() => setShowTestResultDialog(false)}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  standardTemplates: state.modules.standard_templates,
  healthMetricTypes: state.app.health_metric_types,
  healthMetricGroups: state.app.health_metric_groups,
  teams: state.modules.teams,
});

const mapDispatchToProps: IMyTeamEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadTeamConfigItem,
  updateConfigItem: actionCreator.updateTeamConfigItem,
  loadTeams: actionCreator.loadTeamConfigItems,
  loadStandardTemplates: actionCreator.loadStandardTemplates,
  loadHealthMetricTypes: appActionCreator.loadHealthMetricTypes,
  loadHealthMetricGroups: appActionCreator.loadHealthMetricGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTeamEdit);
