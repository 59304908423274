import React, { useCallback, useEffect, useState } from "react";
import { CheckboxVisibility, SelectionMode, Spinner } from "@fluentui/react";
import { ConfigItemType } from "../../../api/radarApi";
import TileDetailsList from "../../../shared/components/Tile/TileDetailsList";
import classNames from "./ConfigHistory.module.scss";
import {
  getCompareButton,
  getSwitchHistoryTypeButton,
  getConfigType,
  getFinalColumns,
  IConfigHistory,
  IPublishHistory,
  liveHistoryColumns,
  stagingHistoryColumns,
  getSelectionColumn,
} from "./ConfigHistory.helper";
import * as radarApi from "../../../api/radarApi";
import ConfigHistoryModal from "./ConfigHistoryModal";
import { match } from "react-router-dom";

export enum ConfigHistoryMode {
  full,
  print,
  simplified,
}

export interface IMatchParams {
  configItemType?: string;
  configItemId?: string;
}

export interface IConfigHistoryProps {
  configHistoryMode?: ConfigHistoryMode;
  configItemId?: string;
  configItemType?: ConfigItemType | string;
  match?: match<IMatchParams>;
}

export const ConfigHistory = (props: IConfigHistoryProps) => {
  const { configHistoryMode, configItemId, configItemType, match } = props;
  const configTypeString = match?.params?.configItemType || configItemType;
  const configType: ConfigItemType = getConfigType(configTypeString);
  const configId = match?.params?.configItemId || configItemId;
  const isLiveOnly = radarApi.LiveOnlyConfigItemTypes.indexOf(configItemType) >= 0;
  const [loading, setLoading] = useState<boolean>(false);
  const [changeHistory, setChangeHistory] = useState<IConfigHistory[]>(undefined);
  const [publishHistory, setPublishHistory] = useState<IPublishHistory[]>(undefined);
  const [showChangeHistory, setShowChangeHistory] = useState<boolean>(isLiveOnly);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    if (configType) {
      setLoading(true);
      radarApi
        .fetchConfigItemHistory(configType, configId, true)
        .then((configHistory: any) => {
          let { change, publish } = configHistory;

          setChangeHistory(change || []);
          setPublishHistory(publish || []);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [configType, configId]);

  const onCompareModelOpen = useCallback(() => setIsCompareModalOpen(true), []);

  const onCompareModelDismiss = useCallback(() => setIsCompareModalOpen(false), []);

  const onSelectionChange = useCallback(
    (selectedItem, checked) => {
      let newItems = selectedItems?.slice() || [],
        itemIndex = newItems.findIndex((item) => item.revision === selectedItem.revision);

      if (checked && newItems.length < 2) {
        if (itemIndex < 0) {
          if (!selectedItem.config) {
            let configDate = showChangeHistory ? selectedItem.changedOn : selectedItem.publishedOn;

            radarApi
              .fetchConfigHistoryItem(configType, configId, configDate, showChangeHistory)
              .then((historyItem: any) => {
                if (historyItem.config) {
                  selectedItem.config = historyItem.config;
                }
              });
          }

          newItems.push(selectedItem);
        }
      } else {
        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        }
      }

      setSelectedItems(newItems);
    },
    [selectedItems, configType, configId, showChangeHistory]
  );

  const onSwitchHistoryType = useCallback(
    () =>
      setShowChangeHistory((s) => {
        setSelectedItems([]);
        return !s;
      }),
    []
  );

  let isPrinting = configHistoryMode === ConfigHistoryMode.print,
    historyItems: any[] = showChangeHistory ? changeHistory : publishHistory,
    columns = showChangeHistory ? stagingHistoryColumns : liveHistoryColumns,
    additionalColumns = !isPrinting && [getSelectionColumn(onSelectionChange, selectedItems)],
    finalColumns = getFinalColumns(columns, additionalColumns),
    modeName = showChangeHistory ? "Change" : "Publish",
    tileTitle = modeName + " History",
    commandBarItems = [];

  if (publishHistory?.length || changeHistory?.length) {
    commandBarItems.push(getCompareButton(onCompareModelOpen, selectedItems?.length));

    if (!isLiveOnly) {
      commandBarItems.push(getSwitchHistoryTypeButton(onSwitchHistoryType, showChangeHistory));
    }
  }

  return loading ? (
    <Spinner
      styles={{ root: classNames.spinner, circle: classNames.spinnerCircle, label: classNames.spinnerLabel }}
      label="Loading data..."
    />
  ) : (
    historyItems !== undefined && (
      <div className={`${classNames.ConfigHistory} ${isPrinting ? classNames.ConfigHistoryPrint + " print" : ""}`}>
        <TileDetailsList
          title={tileTitle}
          className={isPrinting ? classNames.ConfigHistoryTilePrint : classNames.ConfigHistoryTile}
          maxContentHeight="700px"
          items={historyItems}
          columns={finalColumns}
          compact={false}
          noItemText={"No History"}
          selectionMode={SelectionMode.multiple}
          checkboxVisibility={CheckboxVisibility.hidden}
          commandBarProps={{
            items: [],
            farItems: commandBarItems,
            styles: { root: { minWidth: 200 } },
          }}
        />
        <ConfigHistoryModal
          isOpen={isCompareModalOpen}
          onDismiss={onCompareModelDismiss}
          selectedItems={selectedItems}
          historyItems={historyItems}
        />
      </div>
    )
  );
};

export default ConfigHistory;
