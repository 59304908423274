import React from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ItemDisplayView } from "./ItemContainer";

export interface IDataContext {
  context: any;
  parentDataContext?: IDataContext;
  field: IField;
  key?: string;
  onFieldValueChange?: (fieldName, newValue) => void;
}

export interface IFieldStatus {
  passed: boolean;
  message?: string;
}

export type BooleanEval = boolean | string | ((dataContext: IDataContext) => boolean);

export enum FieldType {
  boolean,
  button,
  container,
  context,
  contextContainer,
  displayOnly,
  date,
  dateMonth,
  dateText,
  enumeration,
  html,
  items,
  json,
  number,
  richText,
  text,
  textArray,
  customHtml,
}

export interface IField {
  fieldName?: string;
  value?: ((parentDataContext: IDataContext) => string) | string | string[] | number | boolean | object | object[];
  defaultValue?: any;
  minValue?: number;
  maxValue?: number;
  label?: string;
  hints?: string;
  fieldType?: FieldType;
  width?: string;
  valueWidth?: string;
  fillWidth?: boolean;
  height?: string;
  isMultiLine?: boolean;
  multiLineRows?: number;
  required?: BooleanEval;
  disabled?: boolean;
  visible?: BooleanEval;
  lastInRow?: boolean;
  firstInRow?: boolean;
  doNotExpandWidth?: boolean;
  collapsible?: BooleanEval;
  collapsed?: BooleanEval;
  className?: string;
  itemTypeName?: string;
  compact?: boolean;
  regEx?: string;
  style?: React.CSSProperties;
  options?: IDropdownOption[] | ((dataContext: IDataContext) => IDropdownOption[]);
  multiSelect?: boolean;
  allowFullEditor?: boolean;
  fieldProps?: object;
  itemDisplayView?: ItemDisplayView;
  fields?: IField[] | ((dataContext: IDataContext) => IField[]);
  /** Flag a dropdown is case insensitive. Default is false. */
  caseInsensitive?: boolean;
  onValueChange?: (dataContext: IDataContext, newValue: any) => any;
  customTitle?: (dataContext: IDataContext, itemTypeName: string, index: number) => string;
  customTabHeader?: (dataContext: IDataContext, itemTypeName: string, index: number) => string;
  checkIfValid?: (dataContext: IDataContext, newValue: any) => IFieldStatus;
  onClick?: Function;
}
