import React from "react";
import { connect } from "react-redux";
import { ILinkedItem } from "../common/interfaces";
import { IState } from "../../reducers/interfaces";
import { Tile, BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { ContactLink } from "../../shared/components/ContactLink";
import { getFormatContent, ContentDisplayType } from "../../shared/utilities/miscHelper";
import { Icon } from "@fluentui/react/lib/Icon";
import { actionCreator } from "../duck";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import ConfirmModal from "../common/ConfirmModal";
import { errorType } from "../interfaces";
import { getUserId } from "../common/helper";
import classNames from "./ControlLinkedItems.module.scss";

export interface IControlLinkedItemsStateProps {
  userId: string;
  linkedItems: ILinkedItem[];
  controlEditors: string[];
  controlOwner: string;
  saving: boolean;
  error: string;
}

export interface IControlLinkedItemsDispatchProps {
  editControlLinkedItem: (linkedItem: ILinkedItem) => void;
  deleteControlLinkedItem: (linkedItem: ILinkedItem) => void;
}

export interface IControlLinkedItemsState {
  changingLinkedItem: ILinkedItem;
  showDeleteModal: boolean;
}

export class ControlLinkedItems extends React.Component<
  IControlLinkedItemsStateProps & IControlLinkedItemsDispatchProps,
  IControlLinkedItemsState
> {
  state: IControlLinkedItemsState = {
    changingLinkedItem: null,
    showDeleteModal: false,
  };

  render() {
    const { linkedItems, userId, controlEditors, controlOwner, saving, error } = this.props;

    if (!linkedItems || !linkedItems.length || !controlOwner || !controlEditors) return null;

    const { changingLinkedItem, showDeleteModal } = this.state;

    const isEditor = controlEditors.indexOf(userId) >= 0 || controlOwner.indexOf(userId) >= 0,
      changingLinkedItemId = changingLinkedItem && changingLinkedItem.linkedItemId;

    const linkedItemsFinal = linkedItems.filter((linkItem) => {
      if (linkItem.settings?.length) {
        try {
          let settings = JSON.parse(linkItem.settings);

          if (settings?.doNotDisplayInUX) {
            return false;
          }
        } catch {}
      }

      return true;
    });

    return (
      <Tile
        title="Linked Items"
        className={classNames.root}
        commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}
      >
        <div className={classNames.editableItems}>
          {linkedItemsFinal.map((linkedItem) => {
            let status = linkedItem.status,
              statusResolved = status && status?.toLowerCase() === "resolved",
              statusIcon = statusResolved ? "CompletedSolid" : "Blocked2Solid",
              statusIconColor = statusResolved ? "green" : "red";

            return (
              <div key={"li" + linkedItem.linkedItemId} className={classNames.item}>
                <div className={classNames.itemHeader}>
                  {(isEditor || linkedItem.modifiedBy === userId) && !saving && (
                    <div className={classNames.itemActions}>
                      <Icon
                        className={classNames.itemAction}
                        iconName="Edit"
                        title="Edit Linked Item"
                        onClick={() => this.onEditLinkedItem(linkedItem)}
                      />
                      <Icon
                        className={classNames.itemAction}
                        iconName="Delete"
                        title="Delete Linked Item"
                        onClick={() => this.setState({ changingLinkedItem: linkedItem, showDeleteModal: true })}
                      />
                    </div>
                  )}
                  <ContactLink alias={linkedItem.modifiedBy} className={classNames.itemContact} />
                  <span className={classNames.itemTime}>
                    {getFormatContent(linkedItem.modifiedOn, ContentDisplayType.datetimeWithRelativeTime)}
                  </span>
                  {saving && linkedItem.linkedItemId === changingLinkedItemId && (
                    <Spinner
                      styles={{
                        root: classNames.itemSpinner,
                        circle: classNames.itemSpinnerCircle,
                        label: classNames.itemSpinnerLabel,
                      }}
                      size={SpinnerSize.xSmall}
                    />
                  )}
                </div>
                <div className={classNames.itemText}>
                  {status && (
                    <Icon
                      iconName={statusIcon}
                      className={classNames.itemStatusIcon}
                      style={{ color: statusIconColor }}
                      title={status}
                    />
                  )}
                  {linkedItem.itemType}&nbsp;
                  <a
                    href={linkedItem.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Link to ${linkedItem.externalId}`}
                  >
                    {linkedItem.externalId}
                  </a>
                  {linkedItem.title && ": " + linkedItem.title}
                </div>
              </div>
            );
          })}
        </div>
        {showDeleteModal && (
          <ConfirmModal
            content="Are you sure you want to delete this linked item?"
            saving={saving}
            error={error}
            onCommit={() => this.onDeleteLinkedItem()}
            onCancel={() => this.setState({ showDeleteModal: false })}
          />
        )}
      </Tile>
    );
  }

  onDeleteLinkedItem = () => {
    this.props.deleteControlLinkedItem(this.state.changingLinkedItem);
    this.setState({ showDeleteModal: false });
  };

  onEditLinkedItem = (linkedItem) => {
    this.setState({ changingLinkedItem: linkedItem });
    this.props.editControlLinkedItem(linkedItem);
  };
}

const mapStateToProps = (state: IState) => {
  const control = state.modules.selected_control;
  return {
    userId: getUserId(state.app.login_user),
    linkedItems: control && control.linkedItems,
    controlEditors: control && control.editors,
    controlOwner: control && control.owner,
    saving: state.modules.saving_control_linked_item,
    error: state.modules.errors[errorType.controlLinkedItem],
  };
};

const mapDispatchToProps = {
  editControlLinkedItem: actionCreator.editControlLinkedItem,
  deleteControlLinkedItem: actionCreator.deleteControlLinkedItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlLinkedItems);
