import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "./AuditOfAuditsPage.module.scss";
import { ConfigItemType, fetchConfigItem, fetchControlResults, fetchControls } from "../../api/radarApi";
import { ChoiceGroup, Icon, Spinner } from "@fluentui/react";
import { AppContext } from "../../app/App";
import { getFullDateWithShortRelativeTime } from "../../shared/utilities/miscHelper";
import { controlType } from "../controls/HealthReport.helper";

enum ViewType {
  FullView = "Full View",
  OnboardStatus = "Onboard Status",
  SoxOnly = "SOX Only",
  FailOnly = "Fail Only",
}

const choiceGroupStyles = {
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "-10px",
    marginRight: "10px",
    whiteSpace: "nowrap",
  },
};

export const AuditOfAudits = () => {
  const [controlMetadata, setControlMetadata] = useState<any>();
  const [controls, setControls] = useState<any[]>();
  const [controlResults, setControlResults] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [selectedViewType, setSelectedViewType] = useState<string>(ViewType.FullView);
  const { appState } = useContext(AppContext);
  const { isStaging } = appState;

  useEffect(() => {
    setLoading(true);
    fetchConfigItem(ConfigItemType.AppSettings, "ControlMetadata", true)
      .then((configItem: any) => {
        setControlMetadata(configItem?.settings);
        fetchControls(true, isStaging)
          .then((controls: any) => {
            setControls(controls);
            fetchControlResults(true, isStaging)
              .then((controlResults: any) => {
                setControlResults(controlResults);
              })
              .catch((error) => setError(error))
              .finally(() => setLoading(false));
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [isStaging]);

  const getControlCategoryContent = (
    controls: any[],
    controlResults: any[],
    controlCategories: any[],
    kpiType,
    kpiSubType
  ) => {
    return controlCategories?.map((category) => {
      let targetControls = controls?.filter(
        (control) =>
          control.controlCategory === category.id &&
          control.financialKpiType === kpiType.id &&
          control.financialKpiSubType === kpiSubType.id
      );

      const liveControls = targetControls?.filter((control) => isOnboarded(control));
      const controlCount = targetControls?.length || 0;
      const liveCount = liveControls?.length || 0;
      const livePercent = Math.round((liveCount * 100) / controlCount);

      return (
        <td className={classNames.controlsCell}>
          {selectedViewType !== ViewType.FailOnly && selectedViewType !== ViewType.SoxOnly && controlCount > 0 && (
            <div
              className={classNames.onboardingStatus}
              title={`${livePercent}% of such controls are "In Production" and not offline.`}
            >
              {liveCount} of {controlCount} ({livePercent}%) Onboarded
            </div>
          )}
          {selectedViewType !== ViewType.OnboardStatus && (
            <div className={classNames.controls}>
              {targetControls?.map((control) => {
                let controlResult = controlResults?.find((result) => result.controlId === control.id);

                if (selectedViewType === ViewType.FailOnly && controlResult?.resultStatus.toLowerCase() !== "fail") {
                  return null;
                }

                if (selectedViewType === ViewType.SoxOnly && !control.soxCompliance) {
                  return null;
                }

                const statusColor =
                  controlResult?.resultStatus.toLowerCase() === "pass"
                    ? "green"
                    : controlResult?.resultStatus.toLowerCase() === "fail"
                    ? "red"
                    : "#aaa";

                const link = control.type === controlType.External ? control.externalUrl : `/#/control/${control.id}`;

                return (
                  <div className={classNames.control}>
                    <div className={classNames.status} style={{ backgroundColor: `${statusColor}` }} />
                    {control.soxCompliance && (
                      <div className={classNames.soxTag} title="This is a SOX compliance control">
                        SOX
                      </div>
                    )}
                    <a
                      className={classNames.controlName}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      title={`Id: ${control.id}\nStatus: ${
                        controlResult?.resultStatus || undefined
                      }\nOnboarded: ${isOnboarded(control)}\nSox Compliance: ${
                        control.soxCompliance || false
                      }\nOffline: ${control.offline || false}\nLast Result: ${
                        controlResult?.createdOn && getFullDateWithShortRelativeTime(controlResult?.createdOn)
                      }`}
                    >
                      {control.shortName || control.name}
                      {control.type === controlType.External && (
                        <Icon className={classNames.linkIcon} iconName="Link" />
                      )}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </td>
      );
    });
  };

  const isOnboarded = useCallback((control) => control.onboardingStatus === 4 && !control.offline, []);

  return (
    <div className={classNames.content}>
      {loading && <Spinner label="Loading data..." />}
      {!loading && error && <div className={classNames.error}>{error}</div>}
      {!loading && !error && controls?.length && (
        <>
          <div className={classNames.topArea}>
            <div className={classNames.legends}>
              <span className={classNames.dot} style={{ backgroundColor: "green" }} />
              Pass
              <span className={classNames.dot} style={{ backgroundColor: "red" }} />
              Fail
              <span className={classNames.dot} style={{ backgroundColor: "#aaa" }} />
              Pending Development / Offline / External
            </div>
            <div className={classNames.actions}>
              <ChoiceGroup
                className={classNames.toggle}
                styles={choiceGroupStyles}
                options={Object.values(ViewType).map((value) => ({ key: value, text: value }))}
                selectedKey={selectedViewType}
                onChange={(ev, option) => setSelectedViewType(option.key)}
              />
            </div>
          </div>
          <table className={classNames.table} cellSpacing={0}>
            <tr>
              <th>Financial KPI Type</th>
              <th>Sub Type</th>
              {controlMetadata?.controlCategories?.map((category) => (
                <th>{category.name}</th>
              ))}
            </tr>
            {controlMetadata?.financialKpiTypes?.map((kpiType) => (
              <>
                <tr>
                  <td className={classNames.type} rowSpan={kpiType.subTypes?.length}>
                    {kpiType.name}
                  </td>
                  {kpiType.subTypes?.length && (
                    <>
                      <td className={classNames.subType}>{kpiType.subTypes[0].name}</td>
                      {getControlCategoryContent(
                        controls,
                        controlResults,
                        controlMetadata?.controlCategories,
                        kpiType,
                        kpiType.subTypes[0]
                      )}
                    </>
                  )}
                </tr>
                {kpiType.subTypes?.length > 1 &&
                  kpiType.subTypes.map((subType, index) => {
                    if (index >= 1) {
                      return (
                        <tr>
                          <td className={classNames.subType}>{subType.name}</td>
                          {getControlCategoryContent(
                            controls,
                            controlResults,
                            controlMetadata?.controlCategories,
                            kpiType,
                            subType
                          )}
                        </tr>
                      );
                    }
                    return null;
                  })}
              </>
            ))}
          </table>
        </>
      )}
    </div>
  );
};

export default AuditOfAudits;
